import {z} from 'zod';
import {toast} from 'react-toastify';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {VerdocsButton, VerdocsSpinner} from '@verdocs/web-sdk-react';
import {IApiKey, createApiKey, VerdocsEndpoint} from '@verdocs/js-sdk';
import {useOrganizationMembers} from '../react-sdk/queries';
import {CheckboxInput, SelectInput, TextInput} from '../Components';
import {AppState} from '../AppState';
import {Dialog} from './Dialog';

const schema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  profile_id: z.string().min(1, 'Please select an owner'),
  global_admin: z.coerce.boolean(),
});

type SchemaType = z.infer<typeof schema>;

interface ApiKeyDialogProps {
  onClose: () => void;
  onCreated: (key: IApiKey) => void;
}

export const CreateApiKeyDialog: FC<ApiKeyDialogProps> = ({onClose, onCreated}) => {
  const profileId = AppState.currentProfile?.id || '';
  const organizationId = AppState.currentProfile?.organization_id || '';

  const membersQuery = useOrganizationMembers(organizationId);
  const [submitting, setSubmitting] = useState(false);

  const profiles = (membersQuery.data || []).map((member) => ({label: `${member.first_name} ${member.last_name}`, value: member.id}));
  console.log('profiles', profiles);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {name: '', profile_id: profileId, global_admin: false},
  });

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return createApiKey(VerdocsEndpoint.getDefault(), data)
      .then((r) => {
        setSubmitting(false);
        form.reset(r);
        toast.success('API key created');
        onCreated(r);
      })
      .catch((e: any) => {
        setSubmitting(false);
        toast.error(`Error creating API key: ${e.message}`);
      });
  };

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Create API Key</h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <TextInput label="Name" placeholder="A name to help identify the key in the console" {...form.register('name')} required />

          <SelectInput options={profiles} name="profile_id" label="Profile" />
          <p className="text-sm mb-8">Actions performed via API calls using this key will "act as" this profile.</p>

          <CheckboxInput name="global_admin" label="Global Admin" />
          <p className="text-sm mb-8">
            If set, API calls made with this key will be able to "see" all templates and envelopes, regardless of whether they are shared.
          </p>

          <div className="flex flex-row items-center justify-end mt-10 gap-4">
            <VerdocsButton size="small" variant="outline" label="Cancel" onClick={onClose} disabled={submitting} />
            <VerdocsButton size="small" label="OK" type="submit" disabled={submitting} />
            {submitting && <VerdocsSpinner mode="dark" />}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
