import {z} from 'zod';
import {toast} from 'react-toastify';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {IGroup, updateGroup, VerdocsEndpoint} from '@verdocs/js-sdk';
import {PermissionsGrid} from '../Views/Settings/Components/PermissionsGrid';
import {Button, TextInput} from '../Components';
import {Dialog} from './Dialog';

const schema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  permissions: z.array(z.string()),
});

type SchemaType = z.infer<typeof schema>;

interface DialogProps {
  group: IGroup;
  onClose?: () => void;
}

export const EditGroupDialog: FC<DialogProps> = ({group, onClose = () => {}}) => {
  const [submitting, setSubmitting] = useState(false);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {name: group.name, permissions: group.permissions},
  });

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return updateGroup(VerdocsEndpoint.getDefault(), group.id, data)
      .then((r) => {
        setSubmitting(false);
        form.reset(r);
        toast.success('Group updated');
        onClose();
      })
      .catch((e: any) => {
        setSubmitting(false);
        toast.error(`Error updating group: ${e.message}`);
      });
  };

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Edit Group "{group.name}"</h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <TextInput
            disabled={group.name === 'everyone'}
            label="Name"
            placeholder="A name to help identify this group"
            {...form.register('name')}
            required
          />

          <PermissionsGrid name="permissions" />

          <div className="flex flex-row items-center justify-end mt-10 gap-4">
            <Button size="small" variant="secondary" label="Cancel" onClick={onClose} disabled={submitting} />
            <Button size="small" label="Save" type="submit" disabled={submitting || !form.formState.isDirty} />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
