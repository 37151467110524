import React, {FC, useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import {useFormContext} from 'react-hook-form';
import {PopoverClose} from '@radix-ui/react-popover';
import {Popover, PopoverContent, PopoverTrigger} from './Popover';
import {FormError, FormLabel} from './Layout';

const Swatches = [
  // Verdocs defaults
  '#654dcb',
  '#55bc81',

  // Other Verdocs colors
  '#092c4c',

  // Other common colors
  '#F5A623',
  '#4A90E2',
  '#D0021B',
  '#F8E71C',
  '#8B572A',
  '#417505',
  '#BD10E0',
  '#50E3C2',
  '#000000',
  '#33354c',
  '#5a5a5a',
];

interface IColorPickerProps {
  name: string;
  defaultValue: string;
}

export const ColorPicker: FC<IColorPickerProps> = ({name, defaultValue}) => {
  const form = useFormContext();
  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState(form.getValues(name) || defaultValue);

  const error = form.formState.errors[name];

  return (
    <div className="flex flex-0 flex-col mb-4">
      <Popover onOpenChange={setOpen}>
        <PopoverTrigger>
          <div className="flex flex-row items-center">
            <div className="border border-gray-300 rounded-sm flex flex-row items-center pr-3 bg-gray-200 hover:bg-gray-100">
              <div className="size-8 mr-4" style={{backgroundColor: form.getValues(name) || defaultValue}}></div>
              Select
            </div>
          </div>
        </PopoverTrigger>
        {open && (
          <PopoverContent className="p-0 m-0 w-[200px]">
            <HexColorPicker color={localValue} onChange={(e) => setLocalValue(e)} />

            <div className="my-2 flex flex-row flex-wrap gap-1.5 px-2">
              {Swatches.map((color) => (
                <div className="size-5" style={{backgroundColor: color}} onClick={() => setLocalValue(color)} />
              ))}
            </div>

            <div className="px-2 pb-2">
              <input className="border rounded" type="text" value={localValue} onChange={(e) => setLocalValue(e.target.value)} />
            </div>

            <div className="px-2 pb-2">
              <PopoverClose
                className="text-center w-full border rounded bg-green-600 text-white py-1"
                onClick={() => form.setValue(name, localValue, {shouldDirty: true})}>
                Done
              </PopoverClose>
            </div>
          </PopoverContent>
        )}
      </Popover>
      <FormError role="alert">{error ? String(error.message) : ''}</FormError>
    </div>
  );
};
