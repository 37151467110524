import React, {FC} from 'react';
import {useFormContext} from 'react-hook-form';

export interface ICheckboxInputProps {
  label: string;
  name: string;
  explainer?: string;
}

export const CheckboxInput: FC<ICheckboxInputProps> = ({label, name, explainer}) => {
  const form = useFormContext();

  return (
    <div className="flex flex-row mb-4 items-center gap-3">
      <input
        id={`field-${name}`}
        type="checkbox"
        {...form.register(name)}
        className="w-5 h-5 flex-[0_0_20px] text-sm rounded-md border border-[#707ae5]"
      />

      <div>
        <label htmlFor={`field-${name}`} className="color-[#444444] font-medium text-sm block">
          {label}
        </label>

        {explainer && <p className="text-gray-500 text-xs leading-5 mt-1">{explainer}</p>}
      </div>
    </div>
  );
};
