import React from 'react';
import {Metric} from 'web-vitals';
import {createRoot} from 'react-dom/client';
import {VerdocsEndpoint} from '@verdocs/js-sdk';
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';
import {onLCP, onINP, onCLS, onFCP, onTTFB} from 'web-vitals';
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import {AnalyticsManager} from './Managers/AnalyticsManager';
import pkg from '../package.json';
import {CONFIG} from './config';
import App from './App';

import './index.css';
import 'react-tooltip/dist/react-tooltip.css';

AnalyticsManager.init();

if (window.location.hostname !== 'localhost') {
  datadogLogs.init({
    clientToken: 'pub1bcbc40fde481ca335be5fdb272ecc35',
    site: 'datadoghq.com',
    service: 'web-app',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: CONFIG.IS_BETA ? 'stage' : 'production',
    forwardConsoleLogs: 'all',
    // Per https://docs.datadoghq.com/logs/log_collection/javascript/#discard-specific-logs this is the
    // correct usage, but the method signature expects a boolean to always be returned.
    // @ts-ignore
    beforeSend: (event, _context) => {
      // Filter out some nuisance logs
      if (event.message?.includes('LaunchDarkly') || event.message?.includes('linkedin.com')) {
        return false;
      }
    },
  });

  datadogRum.init({
    applicationId: '8332cf3b-8e61-4513-9837-153a8e114adc',
    clientToken: 'pub130dd06de7e2cc49470f434e171f121f',
    site: 'datadoghq.com',
    service: 'web-app',
    env: CONFIG.IS_BETA ? 'stage' : 'production',
    version: pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const endpoint = VerdocsEndpoint.getDefault() as VerdocsEndpoint;
endpoint //
  .setTimeout(30000)
  .setBaseURL(CONFIG.API_URL)
  .setClientID('verdocs-web')
  .logRequests(true);

(async () => {
  const LDProvider = await asyncWithLDProvider({clientSideID: '66a7a1922cede50f7d573403', timeout: 3000});

  const container = document.getElementById('root') || document.createElement('div');
  const root = createRoot(container);
  root.render(
    <LDProvider>
      <App />
    </LDProvider>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const metricDetails = {
  // See https://web.dev/fcp/. Has any portion of the page rendered?
  FCP: {name: 'First Contentful Paint', good: 1800, warning: 3000},

  // See https://web.dev/lcp/. Has the largest chunk of content rendered?
  LCP: {name: 'Largest Contentful Paint', good: 2500, warning: 4000},

  // See https://web.dev/cls/. Is the page moving around on the user?
  CLS: {name: 'Cumulative Layout Shift', good: 0.1, warning: 0.25},

  // See https://web.dev/inp/. Is the user trying to click on things whose event handlers are not yet "ready"?
  INP: {name: 'Interaction to Next Paint', good: 200, warning: 500},

  // See https://web.dev/fid/. Is the user trying to click on things whose event handlers are not yet "ready"?
  FID: {name: 'First Input Delay', good: 100, warning: 300},

  // See https://web.dev/time-to-first-byte/. Is the server slow?
  TTFB: {name: 'Time to First Byte', good: 500, warning: 1000},
};

const reportWebVitals = (metric: Metric) => {
  const details = metricDetails[metric.name];
  console.log(`${details.name}: ${Math.floor(metric.value)}`);
};

onCLS(reportWebVitals);
onINP(reportWebVitals);
onLCP(reportWebVitals);
onFCP(reportWebVitals);
onTTFB(reportWebVitals);
