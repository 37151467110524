import {z} from 'zod';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import React, {FC, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {acceptOrganizationInvitation, declineOrganizationInvitation, VerdocsEndpoint} from '@verdocs/js-sdk';
import {queryClient, useInvitation} from '../../react-sdk/queries';
import {Button, StandardView, TextInput} from '../../Components';
import {URLS} from '../../Routing';

const passwordValidation = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);

const signupSchema = z
  .object({
    token: z.string(),
    email: z.string(),
    first_name: z.string().trim().min(1, 'First name is required'),
    last_name: z.string().trim().min(1, 'Last name is required'),
    password: z
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters and include a mix of lower-case, upper-case and special characters.')
      .regex(passwordValidation, {
        message: 'Password must be at least 8 characters and include a mix of lower-case, upper-case and special characters.',
      }),
    confirm_password: z.string().trim().min(1, 'Password is required'),
  })
  .superRefine(({confirm_password, password}, ctx) => {
    if (confirm_password !== password) {
      ctx.addIssue({code: 'custom', message: 'Passwords do not match', path: ['confirm_password']});
    }
  });

type SignupSchemaType = z.infer<typeof signupSchema>;

export const AcceptInvite: FC = observer(() => {
  const navigate = useNavigate();
  const {email, token} = useParams();
  const [working, setWorking] = useState(false);
  const [declined, setDeclined] = useState(false);
  const {data: invitation, isLoading, isError} = useInvitation(email!, token!);

  useEffect(() => {
    // TODO: Remove at next js-sdk update
    // @ts-ignore
    if (invitation?.status === 'declined') {
      setDeclined(true);
    }
  }, [invitation]);

  const form = useForm<SignupSchemaType>({
    mode: 'all',
    resolver: zodResolver(signupSchema),
    defaultValues: {token: '', email: '', first_name: '', last_name: '', password: '', confirm_password: ''},
  });

  useEffect(() => {
    if (invitation) {
      form.reset({
        token: invitation.token || '',
        email: invitation.email || '',
        first_name: (invitation as any).first_name || '',
        last_name: (invitation as any).last_name || '',
      });
    }
  }, [invitation, form.reset]);

  const handleDecline = () => {
    setWorking(true);
    console.log('Declining invitation');
    declineOrganizationInvitation(VerdocsEndpoint.getDefault(), email!, token!)
      .then((r) => {
        setWorking(false);
        console.log('Declined', r);
        toast.info(
          'You have declined this invitation. You will not receive future invitations or reminders to join this organization. You may now close this window.',
        );
        setDeclined(true);
      })
      .catch((e) => {
        toast.error('Error declining invitation. It may have already expired.');
        // Leaving this disabled on purpose
        // setWorking(false);
        console.log(e);
      });
  };

  const handleAccept = (values: SignupSchemaType) => {
    setWorking(true);
    acceptOrganizationInvitation(VerdocsEndpoint.getDefault(), {
      email: values.email,
      token: token!,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
    })
      .then(async (r) => {
        setWorking(false);
        console.log('Accepted invite', r);
        VerdocsEndpoint.getDefault().setToken(r.access_token);
        queryClient.clear();
        navigate(URLS.DASHBOARD.build());
        window.location.reload();
      })
      .catch((e) => {
        setWorking(false);
        toast.error('Error declining invitation. It may have already expired.');
        console.log(e);
      });
  };

  if (!email || !token || isError) {
    return (
      <StandardView>
        <div className="flex flex-col items-center pt-8 text-center px-8 text-lg">
          <div>
            Sorry, that invitation is not valid. Please make
            <br />
            sure the invitation you are accepting has not been canceled.
          </div>

          <div className="pt-8 flex flex-row gap-8">
            <Button size="xsmall" label="Go to Dashboard" onClick={() => (window.location.href = '/')} />
          </div>
        </div>
      </StandardView>
    );
  }

  if (declined) {
    return (
      <StandardView>
        <div className="flex flex-col items-center pt-8 text-center px-8 text-lg">
          <div>This invitation has been declined and is no longer valid.</div>
        </div>
      </StandardView>
    );
  }

  if (isLoading) {
    return (
      <StandardView>
        <div className="flex flex-col items-center pt-8 text-center px-8 text-lg">
          <div>Loading...</div>
        </div>
      </StandardView>
    );
  }

  return (
    <StandardView>
      <div className="flex flex-col items-center pt-8">
        <div className="flex items-center flex-col gap-6 w-[360px] max-w-full">
          <div className="text-lg font-semibold items-center flex flex-col">
            {invitation?.organization?.full_logo_url || invitation?.organization?.thumbnail_url ? (
              <img
                alt="Logo"
                className="max-w-48 object-contain"
                src={invitation?.organization?.full_logo_url || invitation?.organization?.thumbnail_url || ''}
              />
            ) : (
              <img src="/assets/blue-logo.svg" alt="logo" className="size-32" />
            )}
          </div>

          <div className="text-lg font-semibold text-center">
            You have been invited to join
            <br /> {invitation?.organization?.name} at Verdocs.
          </div>

          <div className="text-center text-base">Create your profile to get started.</div>

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleAccept)} className="w-[320px] border bg-white p-4">
              <TextInput label="First Name" name="first_name" required />
              <TextInput label="Last Name" name="last_name" required />
              <TextInput label="Email Address" disabled name="email" />
              <TextInput label="Password" type="password" required name="password" />
              <TextInput label="Confirm Password" type="password" required name="confirm_password" />

              <div className="flex flex-row justify-center gap-4">
                <VerdocsButton size="small" label="Decline" type="submit" disabled={working} onClick={handleDecline} />
                <VerdocsButton size="small" label="Accept" type="submit" disabled={working || !form.formState.isValid} />
              </div>
            </form>
          </FormProvider>
        </div>

        <div className="flex flex-row items-center mt-2 justify-center gap-2">
          <div>Powered by</div>
          <img src="/assets/blue-logo.svg" alt="logo" className="w-16 mb-1" />
        </div>
      </div>
    </StandardView>
  );
});
