import React, {FC} from 'react';
import {unparse} from 'papaparse';
import {format} from '@formkit/tempo';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {IEnvelope, downloadBlob} from '@verdocs/js-sdk';
import {useTemplate, useTemplateEnvelopes} from '../../react-sdk/queries';
import {Button, Content, Spinner, StandardView} from '../../Components';
import {SubmittedDataTable} from './SubmittedDataTable';

// TODO: Move value to single key server-side and move this logic to js-sdk
const getRecipientFieldValue = (envelope: IEnvelope, roleName: string, fieldName: string) => {
  const recipient = envelope.recipients.find((r) => r.role_name === roleName);
  if (!recipient) {
    return '';
  }

  const field = (envelope.fields || []).find((f) => f.name === fieldName);
  if (!field) {
    return '';
  }

  const {value = '',type} = field || {};
  switch (type) {
    case 'textarea':
    case 'date':
    case 'textbox':
    case 'initial':
    case 'signature':
    case 'attachment':
    case 'dropdown':
    case 'checkbox':
    case 'radio':
      return value;

    // Timestamp fields get automatically filled when the envelope is submitted.
    case 'timestamp':
      return format(new Date(envelope.updated_at), 'P p');
  }

  return '';
};

export const SubmittedData: FC = observer(() => {
  const {id = ''} = useParams();
  const templateQuery = useTemplate(id);
  const envelopesQuery = useTemplateEnvelopes(id);

  const headers = ['Date Modified'];
  let firstRow = true;
  const submittedData =
    templateQuery.data && envelopesQuery.data
      ? (envelopesQuery.data?.envelopes || []).map((envelope) => {
          const columns = [];
          columns.push(format(new Date(envelope.updated_at), 'M/D/YYYY h:mm A', 'en'));

          (templateQuery.data.roles || []).forEach((role) => {
            (envelope.fields || [])
              .filter((field) => field.role_name === role.name)
              .forEach((field) => {
                columns.push(getRecipientFieldValue(envelope, role.name, field.name));
                if (firstRow) {
                  headers.push(field.name);
                }
              });
          });

          firstRow = false;
          return columns;
        })
      : [];

  const handleDownload = () => {
    const csv = unparse({fields: headers, data: submittedData}, {quotes: true});
    const csvBlob = new Blob([csv], {type: 'plain/text'});
    downloadBlob(csvBlob, `${templateQuery.data?.name || 'Unknown Template'} - Submitted Data.csv`);
  };

  return (
    <StandardView>
      <Content>
        <h1 className="flex text-2xl mb-4 font-medium">
          Submitted Data {templateQuery.data ? `for "${templateQuery.data.name}"` : ''}
          <div className="flex flex-1" />
          <Button label="Download (CSV)" onClick={handleDownload} />
        </h1>

        {templateQuery.isLoading || !templateQuery.data ? (
          <Spinner />
        ) : (
          <SubmittedDataTable template={templateQuery.data} envelopes={envelopesQuery.data?.envelopes || []} />
        )}
      </Content>
    </StandardView>
  );
});
