import React, {FC} from 'react';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {CalendarDaysIcon, TrashIcon} from '@heroicons/react/24/outline';
import {InboxIcon, ArrowPathIcon, ClockIcon} from '@heroicons/react/24/solid';
import {deleteOrganizationInvitation, resendOrganizationInvitation, VerdocsEndpoint} from '@verdocs/js-sdk';
import {invalidateQuery, useOrganizationInvites} from '../../../react-sdk/queries';
import {AppState} from '../../../AppState';
import {Spinner, Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../../Components';

export const InvitesTable: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id;

  const [working, setWorking] = React.useState(false);
  const invitesQuery = useOrganizationInvites(organizationId);
  const invites = invitesQuery.data || [];

  if (!invites.length) {
    return <div className="">You have no pending invites. Click Invite New Member to add one now.</div>;
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="pl-0 tablet:pl-4">Email Address</TableHead>
            <TableHead className="text-center hidden tablet:table-cell">Status</TableHead>
            <TableHead className="text-center hidden desktop:table-cell">Sent</TableHead>
            <TableHead className="text-center hidden tablet:table-cell">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {invites.map((invite) => (
            <TableRow key={invite.email}>
              <TableCell className="pl-0 tablet:pl-4">
                <InboxIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline overflow-hidden text-ellipsis text-nowrap" />
                {invite.email}
              </TableCell>
              <TableCell className="text-center hidden tablet:table-cell">
                <ClockIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {invite.status}
              </TableCell>
              <TableCell className="text-center hidden desktop:table-cell">
                <CalendarDaysIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {format(new Date(invite.generated_at), 'P p')}
              </TableCell>
              <TableCell className="text-center hidden tablet:table-cell">
                <TrashIcon
                  className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer inline"
                  onClick={() => {
                    setWorking(true);
                    deleteOrganizationInvitation(VerdocsEndpoint.getDefault(), invite.email)
                      .then(() => {
                        setWorking(false);
                        toast.success('The invitation has been deleted.');
                        invalidateQuery(['organization_invites']);
                      })
                      .catch((e) => {
                        setWorking(false);
                        console.log('Delete error', e);
                        toast.error('There was an error deleting the invitation. Please try again later.');
                      });
                  }}
                />
                <ArrowPathIcon
                  className="w-6 h-6 text-gray-400 mr-3 hover:text-blue-600 cursor-pointer inline"
                  onClick={() => {
                    setWorking(true);
                    resendOrganizationInvitation(VerdocsEndpoint.getDefault(), invite.email)
                      .then(() => {
                        setWorking(false);
                        toast.success('The invitation has been resent.');
                        invalidateQuery(['organization_invites']);
                      })
                      .catch((e) => {
                        setWorking(false);
                        console.log('Resend error', e);
                        toast.error('There was an error resending the invitation. Please try again later.');
                      });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {working && (
        <div className="absolute inset-0 bg-[#0000007f] flex flex-col items-center pt-8 text-white font-lg font-semibold">
          <Spinner mode="light" />
          <div className="mt-4">Please wait...</div>
        </div>
      )}
    </>
  );
});
