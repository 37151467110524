import {z} from 'zod';
import {toast} from 'react-toastify';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {createGroup, VerdocsEndpoint} from '@verdocs/js-sdk';
import {VerdocsButton, VerdocsSpinner} from '@verdocs/web-sdk-react';
import {PermissionsGrid} from '../Views/Settings/Components/PermissionsGrid';
import {TextInput} from '../Components';
import {Dialog} from './Dialog';

const schema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  permissions: z.array(z.string()),
});

type SchemaType = z.infer<typeof schema>;

interface GroupDialogProps {
  onClose: () => void;
  onCreated: () => void;
}

export const CreateGroupDialog: FC<GroupDialogProps> = ({onClose, onCreated}) => {
  const [submitting, setSubmitting] = useState(false);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {name: '', permissions: []},
  });

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return createGroup(VerdocsEndpoint.getDefault(), data)
      .then((r) => {
        setSubmitting(false);
        form.reset(r);
        toast.success('Group created');
        onCreated();
      })
      .catch((e: any) => {
        setSubmitting(false);
        toast.error(`Error creating group: ${e.message}`);
      });
  };

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Create Group</h1>

      <div className="flex h-[410px] overflow-y-scroll pr-6">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <TextInput label="Name" placeholder="A name to help identify this group" {...form.register('name')} required />

            <PermissionsGrid name="permissions" />

            <div className="flex flex-row items-center justify-end mt-10 gap-4">
              <VerdocsButton size="small" variant="outline" label="Cancel" onClick={onClose} disabled={submitting} />
              <VerdocsButton size="small" label="OK" type="submit" disabled={submitting} />
              {submitting && <VerdocsSpinner mode="dark" />}
            </div>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
};
