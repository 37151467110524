import {twMerge} from 'tailwind-merge';
import {NavLink, Outlet} from 'react-router-dom';
import React, {FC, useEffect} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useCurrentProfile} from '../../react-sdk/queries';
import {CONFIG} from '../../config';
import {URLS} from '../../Routing';

const MenuHeader: FC<{label: string}> = ({label}) => <div className="hidden tablet:block font-bold text-sm mt-6">{label}</div>;

const MenuItem: FC<{to: string; label: string; disabled?: boolean}> = ({to, label, disabled = false}) => {
  return (
    <NavLink
      className={({isActive}) =>
        twMerge(
          'inline-block mx-4 cursor-pointer py-0 text-sm no-underline text-gray-700 font-medium',
          'tablet:block tablet:mx-0 tablet:py-2',
          disabled ? 'cursor-default text-gray-300' : '',
          isActive ? 'text-[#707ae5] font-bold' : '',
        )
      }
      to={to}>
      {label}
    </NavLink>
  );
};

export const Settings: FC = () => {
  const currentProfile = useCurrentProfile();
  const {groupManagement} = useFlags();

  const organizationId = currentProfile?.organization_id || '';

  const isPersonal = organizationId === CONFIG.PERSONAL_ORGID;
  const roles = currentProfile?.roles || [];
  const isAdmin = !isPersonal && (roles.includes('owner') || roles.includes('admin'));

  useEffect(() => {
    document.getElementById('verdocs-dropdown-menu-items')?.remove();
  });

  return (
    <div className="flex flex-col tablet:flex-row w-full h-full overflow-y-scroll">
      <div className="flex flex-row flex-wrap pt-2 tablet:pt-0 tablet:flex-col bg-gray-100 pl-2 pr-4 tablet:pl-4 tablet:pr-10 flex-[0_0_110px]">
        <MenuHeader label="Personal" />
        <MenuItem to={URLS.SETTINGS_PROFILE.build()} label="My Profile" />
        <MenuItem to={URLS.SETTINGS_SECURITY.build()} label="Security" />

        <MenuHeader label="Organization" />
        <MenuItem to={URLS.SETTINGS_ORGANIZATION.build()} disabled={!isAdmin} label="Org Profile" />
        {/*<MenuItem to={URLS.SETTINGS_USAGE.build()} label="Usage" />*/}
        {groupManagement && <MenuItem to={URLS.SETTINGS_GROUPS.build()} disabled={!isAdmin} label="Groups" />}
        <MenuItem to={URLS.SETTINGS_MEMBERS.build()} disabled={!isAdmin} label="Members" />
        <MenuItem to={URLS.SETTINGS_API_KEYS.build()} disabled={!isAdmin} label="API Keys" />
        <MenuItem to={URLS.SETTINGS_WEBHOOKS.build()} disabled={!isAdmin} label="Webhooks" />

        {/*<MenuHeader label="Danger!" />*/}
        {/*<MenuItem to={URLS.SETTINGS_DELETEORG.build()} disabled={!isAdmin} label="Archive Org." />*/}
      </div>

      <div className="flex flex-col h-full p-4 tablet:p-6 flex-1 overflow-x-hidden">
        <Outlet />
      </div>
    </div>
  );
};
