import {z} from 'zod';
import React, {FC} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
import {deleteOrganization, VerdocsEndpoint} from '@verdocs/js-sdk';
import {FormColumn, FormColumns, TextInput} from '../../Components';
import {AppState} from '../../AppState';

export const DeleteOrg: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id || '';

  const schema = z.object({
    name: z.literal(AppState.currentProfile?.organization?.name || '', {
      errorMap: () => ({message: "Please enter your organization's name exactly as shown above."}),
    }),
  });

  type SchemaType = z.infer<typeof schema>;

  const navigate = useNavigate();
  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {name: ''},
  });

  const onSubmit = async (data: any) => {
    await deleteOrganization(VerdocsEndpoint.getDefault(), organizationId)
      .then((r) => {
        if (r) {
          console.log('Organization archived, new profile, going to dashboard', r);
          VerdocsEndpoint.getDefault().setToken(r.access_token);
          navigate('/', {replace: true});
        } else {
          console.log('Organization archived, no profile left, logging out', r);
          VerdocsEndpoint.getDefault().clearSession();
          navigate('/login', {replace: true});
        }

        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((e) => toast.error(`Error archiving organization: ${e.message}`));
  };

  const dirty = form.formState.isDirty;

  return (
    <div className="p-8 w-full max-w-[640px]">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormColumns>
            <FormColumn className="flex-[2]">
              <p className="text-red-600 font-semibold mb-4">
                WARNING: This action is irreversible. All Templates, Envelopes, and other data will be
                archived. This data may be obtained via a support request to support@verdocs.com, but
                will not be accessible in any other way (e.g. via this Web app, developer API calls, etc).
              </p>

              <p className="mb-8">
                If you wish to proceed, please enter the organization's name ({AppState.currentProfile?.organization?.name}) below to
                confirm.
              </p>

              <TextInput label="Name" name="name" placeholder="Enter organization name to confirm..." required />
            </FormColumn>
          </FormColumns>

          <VerdocsButton
            type="submit"
            label="Archive Organization"
            size="normal"
            variant="outline"
            className="[&>button.outline]:text-red-600 [&>button.outline]:border-red-600"
            disabled={!form.formState.isValid || form.formState.isSubmitting || !dirty}
          />
        </form>
      </FormProvider>
    </div>
  );
});
