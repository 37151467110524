import React, {FC, PropsWithChildren} from 'react';

const Link: FC<PropsWithChildren<{href: string}>> = ({href, children}) => (
  <a href={href} className="text-white text-xs font-normal hover:underline cursor-pointer no-underline">
    {children}
  </a>
);

export const InfoLinks: FC = () => {
  return (
    <div className="mt-3.5 flex-row flex justify-evenly pb-8 w-[320px]">
      <Link href="https://verdocs.com/privacy-policy/">Privacy Policy</Link>
      <Link href="https://verdocs.com/eula/">End User License Agreement</Link>
    </div>
  );
};
