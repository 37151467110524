import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {useOrganization} from '../../react-sdk/queries';
import {AppState} from '../../AppState';
import {FlexFill, HelpDialog, PageTitle} from '../../Components';

const Entitlement: FC<{name: string; mtdUsage: any; ytdUsage: any; mtdQuota: any; ytdQuota: any}> = ({
  name,
  mtdUsage,
  ytdUsage,
  mtdQuota,
  ytdQuota,
}) => {
  return (
    <tr className="odd:bg-white even:bg-gray-200 [&>td]:px-2">
      <td className="text-nowrap">{name}</td>
      <td className="text-center text-gray-600">
        {mtdUsage} / {mtdQuota}
      </td>
      <td className="text-center text-gray-600">
        {ytdUsage} / {ytdQuota}
      </td>
    </tr>
  );
};

export const OrgUsage: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id || '';

  const {data: organization} = useOrganization(organizationId);

  return (
    <div className="px-4 py-6 w-full max-w-[640px]">
      <div className="mb-8">
        <PageTitle>
          Usage
          <FlexFill />
          <HelpDialog title="Usage">
            All organizations have access to unlimited eSign Standard and Dynamic Tagging features. Additional features may have usage
            limits based on your plan.
          </HelpDialog>
        </PageTitle>

        <table className="table-fixed w-full border">
          <thead>
            <tr className="bg-gray-800 text-white [&>th]:px-2">
              <th className="text-left">Feature</th>
              <th>MTD</th>
              <th>YTD</th>
            </tr>
          </thead>
          <tbody>
            <Entitlement name="eSign Standard" mtdQuota="∞" mtdUsage="0" ytdQuota="∞" ytdUsage="0" />
            <Entitlement name="Dynamic Tagging" mtdQuota="∞" mtdUsage="0" ytdQuota="∞" ytdUsage="0" />
            {/*<Entitlement name="PIN-based KBA" quota="Unlimited" usage="41" />*/}
            {/*<Entitlement name="Full-Identity KBA" quota="250" usage="12" />*/}
          </tbody>
        </table>
      </div>
    </div>
  );
});
