import styled from '@emotion/styled';
import React, {FC, PropsWithChildren} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  bottom: 0;
  background-color: #0000007f;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  padding: 30px 20px 20px;
  width: 500px;
  max-width: 100%;
  display: flex;
	flex-direction: column;
  border-radius: 6px;
  background: #ffffff;
  font-size: 18px;
`;

const Buttons = styled.div`
  display: flex;
  margin: 40px 0 0 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  verdocs-button {
    margin-left: 10px;
  }
`;

interface CloseDialogProps {
  onClose: () => void;
}

export const CloseDialog: FC<PropsWithChildren<CloseDialogProps>> = ({children, onClose}) => {
  return (
    <Background>
      <Content>
        {children}

        <Buttons>
          <VerdocsButton size="medium" label="Close" onClick={onClose} />
        </Buttons>
      </Content>
    </Background>
  );
};
