import {toast} from 'react-toastify';
import styled from '@emotion/styled';
import {useLocation} from 'react-router-dom';
import {VerdocsEndpoint} from '@verdocs/js-sdk';
import React, {FC, useEffect, useState} from 'react';
import {ArrowsRightLeftIcon} from '@heroicons/react/24/solid';
import {VerdocsButton, VerdocsTextInput} from '@verdocs/web-sdk-react';

const View = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #4c56cb;
`;

const Wrapper = styled.div`
  width: 450px;
  display: flex;
  height: 400px;
  flex: 0 0 400px;
  flex-direction: column;
`;

const Dialog = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  verdocs-auth {
    background: white;

    .login-form {
      background: white;
    }
  }
`;

export const OAuth2Authorize: FC = () => {
  const {search} = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [oauth2Config, setoauth2Config] = useState<{logo_uri: string; friendly_name: string} | null>(null);

  const params = new URLSearchParams(search);
  const client_id = params.get('client_id') || '';
  const redirect_uri = params.get('redirect_uri') || '';
  const state = params.get('state') || '';
  const scope = params.get('scope') || '';

  // PKCE
  // If the authorization server supports the PKCE extension (described in PKCE) then the code_challenge and code_challenge_method
  // parameters will also be present. These must be remembered by the authorization server between issuing the authorization code and
  // issuing the access token.

  useEffect(() => {
    async function getConfig() {
      const params = new URLSearchParams();
      params.set('client_id', client_id);
      params.set('redirect_uri', redirect_uri);
      params.set('state', state);
      params.set('scope', scope);
      const config = await VerdocsEndpoint.getDefault().api.get('/oauth2/config?' + params.toString());
      setoauth2Config(config.data);
    }
    getConfig().catch((e) => console.log(e));
  }, [client_id, redirect_uri, state, scope]);

  const handleLogin = () => {
    console.log('Logging in', username, password);
    const params = {
      client_id,
      redirect_uri,
      scope,
      state,
      response_type: 'code',
      username,
      password,
    };
    console.log('params', params);
    VerdocsEndpoint.getDefault()
      .api.post('/oauth2/authenticate', params)
      .then((r) => {
        console.log('result', r);
        setCode(r.data.code);
        setAuthenticated(true);
      })
      .catch((e) => {
        toast.error(e?.message || 'Login failed');
        console.log('error', e);
      });
  };

  const handleAuthorize = async () => {
    console.log('Will authorize', code);
    const params = new URLSearchParams();
    params.set('state', state);
    params.set('code', code);
    params.set('client_id', client_id);
    params.set('scope', scope);
    const url = `${redirect_uri}?${params.toString()}`;
    console.log('url', url);
    window.location.assign(url);
  };

  return (
    <View>
      <Wrapper>
        <Dialog>
          <div
            style={{
              flexDirection: 'row',
              flex: 0,
              justifyContent: 'space-evenly',
              alignItems: 'center',
              paddingTop: 30,
              paddingBottom: 30,
            }}>
            <img src={oauth2Config?.logo_uri || 'https://via.placeholder.com/150'} alt="logo" style={{width: 64, height: 64}} />
            <ArrowsRightLeftIcon style={{width: 48, height: 48, marginLeft: 60, marginRight: 60}} />
            <img src="/logo192.png" alt="logo" style={{width: 64, height: 64}} />
          </div>
          {!authenticated ? (
            <form style={{width: 300, display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 40}}>
              <VerdocsTextInput onInput={(e: any) => setUsername(e.target.value)} label="Username" />
              <VerdocsTextInput onInput={(e: any) => setPassword(e.target.value)} label="Password" type="password" />
              <div style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                <VerdocsButton label="Log In" variant="standard" onClick={handleLogin} />
              </div>
            </form>
          ) : (
            <div>
              <div style={{display: 'block'}}>
                Allow {oauth2Config?.friendly_name || 'Unknown App'} <strong>read-write</strong> access to your:
              </div>
              <div>
                <ul>
                  <li>Templates</li>
                  <li>Envelopes</li>
                  <li>Document Attachments</li>
                  <li>Account Settings</li>
                  <li>Billing Information</li>
                </ul>
              </div>

              <div style={{display: 'flex', flexDirection: 'row', marginBottom: 30, marginTop: 20, justifyContent: 'space-evenly'}}>
                <VerdocsButton label="Cancel" variant="outline" onClick={() => {}} />
                <VerdocsButton label="Authorize" variant="standard" onClick={handleAuthorize} />
              </div>
            </div>
          )}
        </Dialog>
      </Wrapper>
    </View>
  );
};
