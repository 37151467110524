import {toast} from 'react-toastify';
import {IApiKey} from '@verdocs/js-sdk';
import React, {FC, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
import {useOrganizationMembers} from '../react-sdk/queries';
import {SelectInput, TextInput} from '../Components';
import {AppState} from '../AppState';
import {Dialog} from './Dialog';

interface CreatedApiKeyDialogProps {
  onClose: () => void;
  apikey: IApiKey;
}

export const CreatedApiKeyDialog: FC<CreatedApiKeyDialogProps> = observer(({onClose, apikey}) => {
  const organizationId = AppState.currentProfile?.organization_id || '';
  const idEl = useRef<HTMLInputElement>(null);
  const secretEl = useRef<HTMLInputElement>(null);
  const membersQuery = useOrganizationMembers(organizationId);
  const profiles = (membersQuery.data || []).map((member) => ({label: `${member.first_name} ${member.last_name}`, value: member.id}));

  const form = useForm<{name: string; profile_id: string; id: string; secret: string}>({
    mode: 'all',
    defaultValues: {name: apikey.name, profile_id: apikey.profile_id, id: apikey.client_id, secret: apikey.client_secret || ''},
  });

  const copyToClipboard = (el: any, value: string) => {
    el.current?.select();
    navigator.clipboard
      .writeText(value)
      .then(() => toast.success('Copied!'))
      .catch((e) => console.log('Error copying to clipboard', e));
  };

  console.log('k', apikey);

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">API Key Details</h1>
      <FormProvider {...form}>
        <form onSubmit={onClose}>
          <TextInput
            label="Name"
            placeholder="A name to help identify the key in the console"
            {...form.register('name')}
            required
            disabled
          />

          {apikey.global_admin ? (
            <div className="text-sm text-red-600 font-semibold mb-4">
              This is a global admin key. It has access to all data in your organization.
            </div>
          ) : (
            <SelectInput options={profiles} name="profile_id" label="Profile" disabled />
          )}

          <p className="text-sm">
            Please save the Client ID and Secret below and use them to authenticate API calls from your application. NOTE:
          </p>

          <ul className="text-sm">
            <li>
              This is the only time the Secret value will be displayed. If you lose it, you will need to regenerate it to create a new one,
              or delete and recreate the key itself.
            </li>
            <li>
              Secret values should <strong>never</strong> be exposed to insecure environments such as front-end Web or mobile applications.
            </li>
          </ul>

          <div className="flex flex-row my-2">
            <input
              value={apikey.client_id}
              readOnly
              ref={idEl}
              className="border-2 px-2 rounded mr-2 bg-gray-50"
              style={{color: 'rgba(0,0,0,.54)', fontSize: '16px', lineHeight: '38px', width: '100%'}}
            />
            <button
              onClick={(e: any) => {
                e.preventDefault();
                copyToClipboard(idEl, apikey.client_id);
              }}
              className="bg-[#d8d8d8] text-center rounded border border-gray-400 px-4 text-gray-700 text-sm font-medium">
              COPY
            </button>
          </div>

          <div className="flex flex-row my-2">
            <input
              value={apikey.client_secret || ''}
              readOnly
              ref={secretEl}
              className="border-2 px-2 rounded mr-2 bg-gray-50"
              style={{color: 'rgba(0,0,0,.54)', fontSize: '16px', lineHeight: '38px', width: '100%'}}
            />
            <button
              onClick={(e: any) => {
                e.preventDefault();
                copyToClipboard(secretEl, apikey.client_secret || '');
              }}
              className="bg-[#d8d8d8] text-center rounded border border-gray-400 px-4 text-gray-700 text-sm font-medium">
              COPY
            </button>
          </div>

          <div className="flex flex-row items-center justify-end mt-10 gap-4">
            <VerdocsButton size="small" label="Close" onClick={onClose} />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
});
