import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getInPersonLink, VerdocsEndpoint} from '@verdocs/js-sdk';
import {VerdocsEnvelopeSidebar, VerdocsSign} from '@verdocs/web-sdk-react';
import {invalidateQuery} from '../../react-sdk/queries';
import {NonScrollingView} from '../../Components';
import {URLS} from '../../Routing';

export const EnvelopeFinish: FC = () => {
  const navigate = useNavigate();
  const {id, role} = useParams();
  const [inviteCode, setInviteCode] = useState('');

  useEffect(() => {
    console.log('Getting in-person link', id, role);
    getInPersonLink(VerdocsEndpoint.getDefault(), id!, role!)
      .then((r) => {
        console.log('Got in-person link', r);
        setInviteCode(r.access_key.key);
      })
      .catch((e) => console.log('Unknown error', e));
  }, [id, role]);

  const handleEnvelopeUpdated = () => {
    invalidateQuery(['envelopes']);
    invalidateQuery(['envelopes', id]);
  };

  return (
    <NonScrollingView className="p-0 tablet:p-0">
      <div className="flex flex-1 flex-row h-[calc(100vh-106px)] overflow-y-scroll">
        <VerdocsSign className="flex-1" envelopeId={id} roleId={role} inviteCode={inviteCode} onEnvelopeUpdated={handleEnvelopeUpdated} />
        <VerdocsEnvelopeSidebar
          envelopeId={id}
          onAnother={(e: any) => e.detail.envelope.template_id && navigate(URLS.TEMPLATE_DETAIL.build(e.detail.envelope.template_id, 'preview'))}
        />
      </div>
    </NonScrollingView>
  );
};
