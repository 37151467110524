import {useQuery} from '@tanstack/react-query';
import {getTemplate, getTemplates, IGetTemplatesParams, VerdocsEndpoint} from '@verdocs/js-sdk';

export const useTemplate = (id: string) =>
  useQuery({queryKey: ['templates', id], queryFn: () => getTemplate(VerdocsEndpoint.getDefault(), id)});

export const useTemplates = (params: IGetTemplatesParams) =>
  useQuery({
    queryKey: ['templates', 'list', params],
    retry: 3,
    staleTime: 60000,
    refetchInterval: 30000,
    queryFn: () => getTemplates(VerdocsEndpoint.getDefault(), params),
    placeholderData: (previousData) => previousData,
  });
