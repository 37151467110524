import React from 'react';
import styled from '@emotion/styled';

export const SpinnerElement = styled.div`
  display: inline-block;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

interface SpinnerProps {
  size?: number;
  mode?: 'light' | 'dark';
}

export const Spinner: React.FC<SpinnerProps> = ({size = 32, mode = 'light'}) => {
  const sizeStyles = {width: `${size}px`, height: `${size}px`, flex: `0 0 ${size}px`};

  const colorStyles =
    mode === 'light'
      ? {border: '3px solid rgba(255, 255, 255, 0.3)', borderTopColor: '#fff'}
      : {border: '3px solid rgba(0, 0, 0, 0.3)', borderTopColor: '#333'};

  return <SpinnerElement style={{...sizeStyles, ...colorStyles}} />;
};
