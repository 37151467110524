import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FlexFill, HelpDialog, PageTitle} from '../../Components';
import {useOrganizationGroups} from '../../react-sdk/queries';
import {GroupsTable} from './Components/GroupsTable';
import {CreateGroupDialog} from '../../Dialogs';

export const Groups: FC = observer(() => {
  const [creating, setCreating] = useState(false);
  const {refetch} = useOrganizationGroups();

  const handleCreatedOrRegenerated = () => {
    setCreating(false);
    return refetch();
  };

  return (
    <div className="p-4">
      <PageTitle>
        Groups
        <FlexFill />
        <HelpDialog title="Groups">
          Groups enable fine-grained permissions assignments to users. Note that group and profile permissions are additive. If a member is
          assigned to a group that does not have a certain permission, but their role does, the user will be permitted to perform that
          operation. You may wish to assign members to the "Basic User" role if you prefer to use groups for all permissions assignments.
        </HelpDialog>
      </PageTitle>

      <GroupsTable />

      <div className="my-4" />
      <VerdocsButton label="Create Group" onClick={() => setCreating(true)} />

      {creating && <CreateGroupDialog onCreated={handleCreatedOrRegenerated} onClose={handleCreatedOrRegenerated} />}
    </div>
  );
});
