import {z} from 'zod';
import {toast} from 'react-toastify';
import {SquarePen} from 'lucide-react';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {VerdocsButton, VerdocsSpinner} from '@verdocs/web-sdk-react';
import {duplicateTemplate, ITemplate, VerdocsEndpoint} from '@verdocs/js-sdk';
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, TextInput} from '../Components';

const schema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
});

type SchemaType = z.infer<typeof schema>;

interface DialogProps {
  template: ITemplate;
  onSave: () => void;
  onCancel: () => void;
}

export const DuplicateTemplateDialog: FC<DialogProps> = ({template, onSave, onCancel}) => {
  const [submitting, setSubmitting] = useState(false);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {
      name: template?.name || '',
    },
  });

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return duplicateTemplate(VerdocsEndpoint.getDefault(), template!.id, data.name)
      .then((r) => {
        console.log('Duplicated', r);
        setSubmitting(false);
        form.reset({name: ''});
        onSave();
      })
      .catch((e) => {
        setSubmitting(false);
        console.log('Error duplicating template', e);
        toast.error(`Error duplicating template. Please try again later.`);
      });
  };

  return (
    <Dialog open onOpenChange={onCancel}>
      <DialogTrigger>
        <SquarePen className="size-6 opacity-70 hover:opacity-100" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogDescription />
          <DialogTitle className="mb-4">Edit Template</DialogTitle>

          <DialogContent>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <TextInput label="Name" placeholder="Name for new template..." {...form.register('name')} required />

                <DialogFooter className="justify-end gap-3 flex-row mt-0 tablet:mt-8">
                  <VerdocsButton size="small" variant="outline" label="Cancel" onClick={onCancel} />
                  <VerdocsButton size="small" label="Duplicate" type="submit" disabled={!form.formState.isValid || submitting} />
                  {submitting && <VerdocsSpinner mode="dark" />}
                </DialogFooter>
              </form>
            </FormProvider>
          </DialogContent>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
