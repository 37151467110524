import React, {FC} from 'react';
import styled from '@emotion/styled';
import {toast} from 'react-toastify';
import {twMerge} from 'tailwind-merge';
import {BuildingOfficeIcon} from '@heroicons/react/24/outline';
import {switchProfile, VerdocsEndpoint} from '@verdocs/js-sdk';
import {CheckIcon, ChevronDownIcon, UserCircleIcon, PlusCircleIcon} from '@heroicons/react/24/solid';
import {Popover, PopoverTrigger, PopoverContent} from './Popover';
import {queryClient, useCurrentProfile, useProfiles} from '../react-sdk/queries';
import {CONFIG} from '../config';
import {URLS} from '../Routing';
import {PopoverArrow} from '@radix-ui/react-popover';

const AccountEntry = styled.a`
  display: flex;
  height: 40px;
  gap: 7px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  min-width: 200px;
  color: #ffffffcc;
  text-decoration: none;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const ProfileChooser: FC = () => {
  const profilesQuery = useProfiles();
  const currentProfile = useCurrentProfile();

  const profiles = profilesQuery.data || [];

  const handleSwitchProfile = async (profileId: string) => {
    try {
      console.log('Switching to profile', profileId);
      const r = await switchProfile(VerdocsEndpoint.getDefault(), profileId);
      VerdocsEndpoint.getDefault().setToken(r.access_token);
      queryClient.clear();
      window.location.reload();
    } catch (e: any) {
      console.log('Error switching profiles', e);
      toast.error('Unable to switch profiles: ' + e.message);
    }
  };

  return (
    <Popover>
      <PopoverTrigger className="flex flex-row gap-3 items-center tablet:mt-3 tablet:mb-8 justify-between w-full">
        {currentProfile?.picture ? (
          <div className="flex flex-[0_0_40px] pl-3 size-8">
            <img src={currentProfile.picture} alt="" className="size-8 rounded-md" />
          </div>
        ) : (
          <div
            className={twMerge(
              'flex flex-[0_0_32px] w-6 h-8 items-center justify-center bg-[#e3e7f7] ml-2',
              'border-[1px_solid_#cccccc] text-[#33364b] font-semibold text-lg rounded-md',
            )}>
            {currentProfile?.first_name?.substring(0, 1)}
            {currentProfile?.last_name?.substring(0, 1)}
          </div>
        )}

        <div className="flex flex-1 flex-col">
          <div className="overflow-hidden whitespace-nowrap text-ellipsis text-sm text-left">
            {currentProfile?.first_name} {currentProfile?.last_name}
          </div>
          <div className="opacity-70 overflow-hidden whitespace-nowrap text-ellipsis text-sm text-left">
            {currentProfile?.organization_id !== CONFIG.PERSONAL_ORGID ? currentProfile?.organization?.name : 'Personal Account'}
          </div>
        </div>

        <div className="flex flex-0 items-center pr-3">
          <ChevronDownIcon width={20} height={20} />
        </div>
      </PopoverTrigger>

      <PopoverContent
        className="bg-[#674fcc] p-4 pb-0 text-white border-0 rounded-none max-h-[410px] overflow-y-scroll overflow-x-hidden ml-2"
        sideOffset={5}>
        <PopoverArrow className="fill-[#674fcc]" />

        <div className="text-sm font-medium">Switch Profile:</div>

        <div className="pb-4">
          {profiles.map((profile) =>
            profile.organization_id === CONFIG.PERSONAL_ORGID ? (
              <AccountEntry key={profile.id} onClick={() => handleSwitchProfile(profile.id)}>
                <UserCircleIcon width={24} height={24} />
                <div className="flex flex-1 flex-col">
                  <div className="m-0 leading-4">
                    {profile.first_name} {profile.last_name}
                  </div>
                  <div className="m-0 text-xs opacity-70 leading-4 italic">Personal</div>
                </div>
                {profile.current && <CheckIcon width={24} height={24} color="#ffffffcc" />}
              </AccountEntry>
            ) : (
              <AccountEntry key={profile.id} onClick={() => handleSwitchProfile(profile.id)}>
                <BuildingOfficeIcon width={24} height={24} />
                <div className="flex flex-1 text-white">{profile.organization?.name}</div>
                {profile.current && <CheckIcon width={24} height={24} color="#ffffffcc" />}
              </AccountEntry>
            ),
          )}

          <AccountEntry href={URLS.CREATE_ORG.build()}>
            <BuildingOfficeIcon width={24} height={24} />
            <div className="flex flex-1 text-white">New Organization</div>
            <PlusCircleIcon width={24} height={24} color="#ffffffcc" />
          </AccountEntry>
        </div>
      </PopoverContent>
    </Popover>
  );
};
