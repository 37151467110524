import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {flexRender, Table} from '@tanstack/react-table';
import {DataTable, DataTableHead, DataTableHeaderCell, DataTableHeaderRow} from './Layout';
import {DataTableBody, DataTableDataCell, DataTableRow} from './Layout';

export const BasicTable: FC<{table: Table<any>}> = observer(({table}) => {
  return (
    <DataTable>
      <DataTableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <DataTableHeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <DataTableHeaderCell key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </DataTableHeaderCell>
            ))}
          </DataTableHeaderRow>
        ))}
      </DataTableHead>
      <DataTableBody>
        {table.getRowModel().rows.map((row) => (
          <DataTableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <DataTableDataCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</DataTableDataCell>
            ))}
          </DataTableRow>
        ))}
      </DataTableBody>
    </DataTable>
  );
});
