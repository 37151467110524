import {HelpCircle} from 'lucide-react';
import {observer} from 'mobx-react-lite';
import React, {FC, PropsWithChildren} from 'react';
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger} from './Dialog';

export const HelpDialog: FC<PropsWithChildren<{title: string}>> = observer(({title, children}) => {
  return (
    <Dialog>
      <DialogTrigger>
        <HelpCircle className="size-6 text-gray-500" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="mb-4">{title}</DialogTitle>

          <DialogDescription className="[&>p]:mb-4">{children}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
});
