import {IApiKey} from '@verdocs/js-sdk';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {CreateApiKeyDialog, CreatedApiKeyDialog} from '../../Dialogs';
import {invalidateQuery} from '../../react-sdk/queries';
import {FlexFill, HelpDialog, PageTitle} from '../../Components';
import {ApiKeysTable} from './Components';

export const ApiKeys: FC = observer(() => {
  const [creating, setCreating] = useState(false);
  const [created, setCreated] = useState<IApiKey | null>(null);

  const handleCreatedOrRegenerated = (key: IApiKey) => {
    setCreating(false);
    setCreated(key);
    invalidateQuery(['api_keys']);
  };

  const handleCancelClose = () => {
    setCreating(false);
    setCreated(null);
    invalidateQuery(['api_keys']);
  };

  return (
    <div className="p-4">
      <PageTitle>
        API Keys
        <FlexFill />
        <HelpDialog title="API Keys">
          API keys allow programmatic access to your organization's Verdocs account, typically used for server-to-server calls by a
          developer. Please note: API access allows callers to act on behalf of your organization, and should only be enabled as needed.
          Store your keys in a safe place, and never share them with a Verdocs employee or third party.
        </HelpDialog>
      </PageTitle>

      <ApiKeysTable onRegenerate={handleCreatedOrRegenerated} />

      <div className="my-4" />
      <VerdocsButton label="Create Key" onClick={() => setCreating(true)} />

      {creating && <CreateApiKeyDialog onCreated={handleCreatedOrRegenerated} onClose={handleCancelClose} />}
      {created && <CreatedApiKeyDialog apikey={created} onClose={handleCancelClose} />}
    </div>
  );
});
