import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {VerdocsInitialDialog} from '@verdocs/web-sdk-react';
import {useSignatures} from '../../react-sdk/queries';
import {Content, SubTitle} from '../../Components';

export const Initials: FC = observer(() => {
  const signaturesQuery = useSignatures();

  console.log('sq', signaturesQuery);

  // TODO: fix fullName
  return (
    <Content>
      <SubTitle>My Initials</SubTitle>

      <VerdocsInitialDialog initials={'CR'} style={{position: 'relative', background: 'none', width: 340}} />
    </Content>
  );
});
