import {observer} from 'mobx-react-lite';
import React, {FC, useEffect} from 'react';
import {Content, StandardView} from '../../Components';
import {EnvelopesSummary} from './EnvelopesSummary';

export const Envelopes: FC = observer(() => {
  // TODO: Get a better component in there
  useEffect(() => {
    document.getElementById('verdocs-dropdown-menu-items')?.remove();
  });

  return (
    <StandardView>
      <Content>
        <EnvelopesSummary />
      </Content>
    </StandardView>
  );
});
