import {observer} from 'mobx-react-lite';
import React, {FC, useEffect} from 'react';
import {RecentActivity} from './RecentActivity';
import {StandardView} from '../../Components';
import {TemplateSummary} from '../Templates';

export const Dashboard: FC = observer(() => {
  // TODO: Get a better component in there
  useEffect(() => {
    document.getElementById('verdocs-dropdown-menu-items')?.remove();
  });

  return (
    <StandardView>
      <RecentActivity />
      <div className="my-4" />
      <TemplateSummary showViewAll rowsPerPage={10} />
    </StandardView>
  );
});
