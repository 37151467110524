import {TRole} from '@verdocs/js-sdk';

export const getRoleLevel = (roleName: TRole) => {
  switch (roleName) {
    case 'owner':
      return 4;
    case 'admin':
      return 3;
    case 'member':
      return 2;
    case 'basic_user':
      return 1;
    case 'contact':
      return 0;
    default:
      return 0;
  }
};

export const getHighestRole = (roles: TRole[]) =>
  roles.find((r) => r === 'owner') ||
  roles.find((r) => r === 'admin') ||
  roles.find((r) => r === 'member') ||
  roles.find((r) => r === 'basic_user') ||
  roles.find((r) => r === 'contact') ||
  roles[0];

export const RoleOptions: {label: string; value: TRole}[] = [
  {label: 'Owner', value: 'owner'},
  {label: 'Admin', value: 'admin'},
  {label: 'Member', value: 'member'},
  {label: 'Basic User', value: 'basic_user'},
  {label: 'Contact', value: 'contact'},
];
