import {observer} from 'mobx-react-lite';
import {userCanSendTemplate} from '@verdocs/js-sdk';
import React, {FC, useEffect, useState} from 'react';
import {DocumentIcon, XMarkIcon} from '@heroicons/react/24/solid';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {VerdocsPreview, VerdocsSend, VerdocsTemplateAttachments, VerdocsTemplateFields} from '@verdocs/web-sdk-react';
import {VerdocsEnvelopeRecipientSummary, VerdocsTemplateRoles} from '@verdocs/web-sdk-react';
import {BreadcrumbTabs, Button, NonScrollingView, Spinner} from '../../Components';
import {EditTemplateDialog, TemplateInfoDialog} from '../../Dialogs';
import {invalidateQuery, useTemplate} from '../../react-sdk/queries';
import {AppState} from '../../AppState';
import {URLS} from '../../Routing';

export const TemplateDetail: FC = observer(() => {
  const {id = ''} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const templateQuery = useTemplate(id);
  const [activeTab, setActiveTab] = useState(location.hash?.replace('#', '').trim() || 'preview');
  const [sending, setSending] = useState(false);
  const [doneSending, setDoneSending] = useState<null | string>(null);

  const template = templateQuery.data;
  const hasAttachments = (template?.documents?.length || 0) > 0;
  const hasRoles = (template?.roles?.length || 0) > 0;
  const isSendable = template?.is_sendable || false;

  useEffect(() => {
    if (template) {
      if (!hasAttachments && activeTab !== 'attachments') {
        console.log('Template has no attachments, overriding tab');
        handleChangeTab('attachments');
      }

      if (!hasRoles && activeTab !== 'roles' && activeTab !== 'attachments') {
        console.log('Template has no roles, overriding tab');
        handleChangeTab('roles');
      }

      if (!userCanSendTemplate(AppState.currentProfile, template) && activeTab !== 'fields') {
        console.log('Not enough fields, overriding tab');
        handleChangeTab('fields');
      }
    }
  }, [template, activeTab, hasAttachments, hasRoles, isSendable]);

  const invalidateTemplateData = () => {
    console.log('Invalidating template data');
    templateQuery.refetch();
  };

  const handleSend = (e: any) => {
    templateQuery.refetch();
    invalidateQuery(['envelopes']);
    setDoneSending(e.detail.envelope_id);
  };

  const handleSetSending = (e: any) => {
    setSending(e.detail.sending);
  };

  const tabs = [
    {
      id: 'attachments',
      label: 'Attachments',
    },
    {
      id: 'roles',
      label: 'Participants',
      disabled: !hasAttachments,
      tooltip: 'Please attach at least one document<br />.',
    },
    {
      id: 'fields',
      label: 'Fields',
      disabled: !hasAttachments || !hasRoles,
      tooltip: 'Please add at least one participant<br />to the workflow.',
    },
    {
      id: 'preview',
      label: 'Preview & Send',
      disabled: !hasAttachments || !hasRoles || !isSendable,
      tooltip: 'Every participant must have at least<br />one field assigned.',
    },
  ];

  const handleChangeTab = (tab: string) => {
    window.location.hash = tab;
    setActiveTab(tab);
  };

  const handleRefresh = () => {
    // TODO: This isn't working.
    window.location.reload();
    // invalidateQuery(['templates']);
    // return templateQuery.refetch();
  };

  return (
    <NonScrollingView style={{padding: 0, position: 'relative'}}>
      <div className="w-full text-white flex z-50 flex-[0_0_56px] px-5 gap-4 items-center flex-row bg-[#33354c] shadow border-l  border-l-gray-800">
        <DocumentIcon className="w-6 h-6 tablet:w-6 tablet:h-6" />
        <div className="text-lg">{template?.name}</div>

        <div className="flex-1" />

        {template && <EditTemplateDialog template={template} onSave={handleRefresh} />}
        {template && <TemplateInfoDialog template={template} />}

        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <XMarkIcon className="w-6 h-6 tablet:w-8 tablet:h-8 opacity-70 hover:opacity-100" />
        </div>
      </div>

      <BreadcrumbTabs tabs={tabs} activeTab={activeTab} onSelect={handleChangeTab} />

      {activeTab === 'attachments' && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col pt-6 pl-4 flex-none w-[340px] laptop:w-[450px] shadow bg-white p-4 mb-8 mt-8">
            <VerdocsTemplateAttachments templateId={id} onExit={() => navigate(-1)} />
          </div>
          <div className="mt-4 flex flex-1 items-start justify-start w-[340px] laptop:w-[450px]">
            <Button size="small" label="Next" onClick={() => handleChangeTab('roles')} disabled={!hasAttachments} />
          </div>
        </div>
      )}

      {activeTab === 'roles' && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col pt-6 pl-4 flex-none mobile:w-[340px] shadow bg-white p-4 mb-8 mt-8">
            <VerdocsTemplateRoles templateId={id} onRolesUpdated={invalidateTemplateData} />
            <div className="mt-4">
              <Button size="small" label="Next" onClick={() => handleChangeTab('fields')} disabled={!hasRoles} />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'loading' && (
        <div className="flex flex-1 w-full h-full pt-8 items-center justify-center">{/*<Spinner size="huge" />*/}</div>
      )}

      {activeTab === 'fields' && (
        <>
          <div id="verdocs-fields-toolbar" />
          <VerdocsTemplateFields
            templateId={id}
            toolbarTargetId="verdocs-fields-toolbar"
            onTemplateUpdated={invalidateTemplateData}
            onFieldsUpdated={invalidateTemplateData}
            className="flex flex-1 flex-col items-center justify-center overflow-y-scroll border-box"
          />
        </>
      )}

      {activeTab === 'preview' && (
        <div className="flex flex-col laptop:flex-row">
          <div className="flex flex-col pt-4 pl-4 flex-none laptop:flex-[0_0_300px]">
            <VerdocsSend templateId={id} onSend={handleSend} onSendingEnvelope={handleSetSending} />
          </div>

          <div className="flex flex-1 flex-col h-[calc(100vh-106px)] overflow-y-scroll">
            <div className="flex flex-1 max-w-[1024px] w-full border-box">
              <VerdocsPreview style={{flex: 1}} templateId={id} />
            </div>
          </div>
        </div>
      )}

      {doneSending && (
        <VerdocsEnvelopeRecipientSummary
          envelopeId={doneSending}
          onView={() => {
            setDoneSending(null);
            navigate(URLS.ENVELOPE_DETAIL.build(doneSending));
          }}
          onAnother={() => {
            setDoneSending(null);
          }}
          onNext={() => {
            setDoneSending(null);
            navigate(URLS.DASHBOARD.build());
          }}
        />
      )}

      {sending && (
        <div className="absolute top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Spinner size={64} />
        </div>
      )}
    </NonScrollingView>
  );
});
