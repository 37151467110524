import React, {FC, PropsWithChildren} from 'react';

interface OkCancelDialogProps {
  onCancel?: () => void;
}

export const Dialog: FC<PropsWithChildren<OkCancelDialogProps>> = ({children, onCancel = () => {}}) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 opacity-80 bg-black"
        onClick={() => {
          console.log('cxl');
          onCancel();
        }}></div>

      <div className="flex px-5 py-4 w-[500px] max-w-full rounded-lg bg-white text-xl flex-col z-50">{children}</div>
    </div>
  );
};
