import React, {FC} from 'react';
import styled from '@emotion/styled';
import {twMerge} from 'tailwind-merge';
import {VerdocsEndpoint} from '@verdocs/js-sdk';
import {NavLink, useNavigate} from 'react-router-dom';
import {DocumentIcon, Bars3Icon, Cog6ToothIcon} from '@heroicons/react/24/solid';
import {EnvelopeOpenIcon, ArrowRightStartOnRectangleIcon} from '@heroicons/react/24/solid';
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger} from '../../Components';
import {ProfileChooser} from '../../Components/ProfileChooser';
import {URLS} from '../../Routing';

const StyledNavLink = styled(NavLink)`
  display: flex;
  color: #f2f5fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  padding: 10px 14px;
  white-space: nowrap;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;

  &.active {
    color: #33364b;
    border: 1px solid #ccc;
    background-color: #e3e7f7;
  }

  @media all and (max-width: 768px) {
    font-size: 14px;
    padding: 4px;
    margin: 0;
    flex: 0;
  }

  @media all and (max-width: 480px) {
    font-size: 13px;
    padding: 0 4px;
    margin: 0;
    flex: 0;
  }
`;

const StyledNavButton = styled.div`
  display: flex;
  color: #f2f5fa;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  padding: 10px 14px;
  white-space: nowrap;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;

  &.active {
    color: #33364b;
    border: 1px solid #ccc;
    background-color: #e3e7f7;
  }

  @media all and (max-width: 768px) {
    font-size: 14px;
    padding: 4px;
    margin: 0;
    flex: 0;
  }

  @media all and (max-width: 480px) {
    font-size: 13px;
    padding: 0 4px;
    margin: 0;
    flex: 0;
  }
`;

// const Separator = () => <div className="flex flex-none h-[1px] bg-[#ffffff3f]" />;
// profile {"id":"e2e41049-abb6-4ddd-b7b9-c1c37d2d3e4d","user_id":"auth0|6018196ed93ea100707a39a5","organization_id":"65b59646-64fe-48df-850f-05887ddf003b","first_name":"Chad","last_name":"Robinson","email":"crobinson@medialantern.com","phone":null,"current":true,"organization":{"id":"65b59646-64fe-48df-850f-05887ddf003b","name":"realster","business_name":null,"is_business":false}}

const SidebarLink: FC<{
  className?: string;
  label: string;
  to?: string;
  onClick?: () => void;
  icon: any;
}> = ({className, label, to, onClick, icon}) => {
  const id = label.toLowerCase().replace(/ /g, '_');
  return to ? (
    <StyledNavLink to={to} id={`menu-item-${id}`} onClick={onClick} className={className}>
      <span className={'mr-2 hidden phablet:inline'}>{icon}</span>
      <span>{label}</span>
    </StyledNavLink>
  ) : (
    <StyledNavButton onClick={onClick} id={`menu-item-${id}`} className={className}>
      <span className={'mr-2 hidden phablet:inline'}>{icon}</span>
      <span>{label}</span>
    </StyledNavButton>
  );
};

export const Sidebar: FC<{onSignout?: () => void}> = ({onSignout}) => {
  const navigate = useNavigate();

  const handleSignout = () => {
    VerdocsEndpoint.getDefault().clearSession();
    const isBeta = window.location.host.includes('beta');
    const isDev = window.location.host.includes('localhost');
    if (!isBeta && !isDev) {
      window.location.replace('https://verdocs.com/');
    } else if (isBeta) {
      window.location.replace('https://www.stage.verdocs.com/');
    } else {
      window.location.reload();
    }
    onSignout?.();
  };

  // NOTE: "tablet" is the main breakpoint between desktop and mobile layouts
  return (
    <div
      style={{transition: 'all 0.3s'}}
      className={twMerge(
        'flex flex-0 flex-row text-gray-100 font-medium box-border bg-[#33364b] items-center py-2',
        'tablet:flex-col tablet:flex-[0_0_200px] tablet:pb-4 tablet:justify-between',
      )}>
      {/* Initial sidebar items */}
      <div className="tablet:w-full">
        <ProfileChooser />

        <div
          className={twMerge(
            //
            'flex-row content-center gap-2 w-full px-3 hidden',
            'tablet:flex tablet:flex-col',
          )}>
          <SidebarLink label="Dashboard" to={URLS.DASHBOARD.build()} icon={<Bars3Icon className="size-4 tablet:size-6" />} />
          <SidebarLink label="Templates" to={URLS.TEMPLATES.build()} icon={<DocumentIcon className="size-4 tablet:size-6" />} />
          <SidebarLink label="Envelopes" to={URLS.ENVELOPES.build()} icon={<EnvelopeOpenIcon className="size-4 tablet:size-6" />} />
          <SidebarLink label="Settings" to={URLS.SETTINGS_BASE.build()} icon={<Cog6ToothIcon className="size-4 tablet:size-6" />} />
        </div>
      </div>

      {/* Additional sidebar items */}
      <div className="items-center flex-col hidden phablet:flex mr-[10%] tablet:mr-0">
        <img
          src={'https://app.verdocs.com/assets/white-logo.svg'}
          alt="Verdocs logo"
          className="w-[100px] mr-4 tablet:mr-0 hidden tablet:block tablet:mb-6"
        />

        <div className="hidden tablet:flex justify-center flex-col gap-0.5 pb-2">
          <a
            href="https://verdocs.com/eula/"
            target="_blank"
            rel="noreferrer"
            className="block text-gray-300 no-underline text-center text-xs cursor-pointer my-1">
            End User License Agreement
          </a>
          <a
            href="https://verdocs.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className="block text-gray-300 no-underline text-center text-xs cursor-pointer my-1">
            Privacy Policy
          </a>

          <div className="my-2" />

          <SidebarLink
            label="Sign Out"
            className="ml-4"
            onClick={handleSignout}
            icon={<ArrowRightStartOnRectangleIcon className="size-4 tablet:w-6 tablet:h-6" />}
          />
        </div>
      </div>

      {/* Mobile menu */}
      <div className="flex flex-1 tablet:hidden" />
      <div className="flex flex-0 tablet:hidden pr-2">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Bars3Icon className="size-6" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => navigate(URLS.DASHBOARD.build())}>Dashboard</DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate(URLS.TEMPLATES.build())}>Templates</DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate(URLS.ENVELOPES.build())}>Envelopes</DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate(URLS.SETTINGS_BASE.build())}>Settings</DropdownMenuItem>

            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <a
                href="https://verdocs.com/eula/"
                target="_blank"
                rel="noreferrer"
                className="block text-gray-500 no-underline text-center text-xs cursor-pointer">
                End User License Agreement
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <a
                href="https://verdocs.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                className="block text-gray-500 no-underline text-center text-xs cursor-pointer">
                Privacy Policy
              </a>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleSignout}>Sign Out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
