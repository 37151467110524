import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {VerdocsEndpoint, deleteOrganizationMember, IGroup, deleteGroup} from '@verdocs/js-sdk';
import {UserGroupIcon, TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline';
import {Spinner, Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../../Components';
import {useOrganizationGroups} from '../../../react-sdk/queries';
import {EditGroupDialog, OkCancelDialog} from '../../../Dialogs';

export const GroupsTable: FC = observer(() => {
  const [working, setWorking] = React.useState(false);
  const [deletingGroupId, setDeletingGroupId] = useState('');
  const [editingGroup, setEditingGroup] = useState<IGroup | null>(null);
  const {data, isLoading, isError, refetch} = useOrganizationGroups();
  const groups = data || [];

  if (isLoading) {
    return <Spinner mode="dark" />;
  }

  if (isError) {
    return <div className="">Unable to load groups. Please try again later.</div>;
  }

  const handleDeleteGroup = async () => {
    setWorking(true);
    try {
      await deleteGroup(VerdocsEndpoint.getDefault(), deletingGroupId);
      refetch().catch((e: any) => console.log('Unknown Error', e));
      toast.info('Group deleted.');
    } catch (e) {
      console.log('e', e);
      toast.error('Error deleting group, please try again later.');
    }
    setDeletingGroupId('');
    setWorking(false);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[50%]">Name</TableHead>
            <TableHead className="w-[110px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {groups.map((group) => (
            <TableRow key={group.id}>
              <TableCell>{group.name}</TableCell>
              <TableCell>
                <div className="flex flex-row gap-2">
                  {group.name !== 'everyone' && (
                    <TrashIcon
                      className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer"
                      onClick={() => setDeletingGroupId(group.id)}
                    />
                  )}

                  <PencilSquareIcon
                    className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer"
                    onClick={() => setEditingGroup(group)}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {deletingGroupId && (
        <OkCancelDialog onCancel={() => setDeletingGroupId('')} onOk={handleDeleteGroup}>
          Are you sure you want to delete this group? This action cannot be undone. Members within the group will not be deleted, but will
          no longer have any permissions conferred by this group.
        </OkCancelDialog>
      )}

      {editingGroup && (
        <EditGroupDialog
          group={editingGroup}
          onClose={() => {
            setEditingGroup(null);
            refetch();
          }}
        />
      )}

      {working && (
        <div className="absolute inset-0 bg-[#0000007f] flex flex-col items-center pt-8 text-white font-lg font-semibold">
          <Spinner mode="light" />
          <div className="mt-4">Please wait...</div>
        </div>
      )}
    </>
  );
});
