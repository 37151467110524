import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {VerdocsInitialDialog, VerdocsSignatureDialog} from '@verdocs/web-sdk-react';
import {PageTitle} from '../../Components';

export const Signature: FC = observer(() => {
  // const signaturesQuery = useSignatures();
  // console.log('sq', signaturesQuery.data);

  return (
    <div>
      <PageTitle>Saved Signature</PageTitle>

      <VerdocsSignatureDialog name={'Chad Robinson'} style={{position: 'relative', background: 'none', width: 340}} />
      <VerdocsInitialDialog initials={'CR'} style={{position: 'relative', background: 'none', width: 340}} />
    </div>
  );
});
