import React, {FC} from 'react';
import {VerdocsAuth} from '@verdocs/web-sdk-react';
import {useLocation, useNavigate} from 'react-router-dom';
import {InfoLinks} from './InfoLinks';
import {URLS} from '../../Routing';

export const Signup: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // See https://github.com/remix-run/react-router/issues/8503#issuecomment-1034263630
  const state = location.state as {originalPath?: string};

  return (
    <div className="flex flex-1 flex-row w-full pt-8 tablet:pt-16 justify-center overflow-y-scroll bg-[#4c56cb]">
      <div className="flex w-[450px] flex-col items-center">
        <VerdocsAuth
          displayMode="signup"
          onAuthenticated={({detail}: any) => {
            if (detail.authenticated) {
              navigate(state?.originalPath || URLS.DASHBOARD.build());
            }
          }}
        />

        <InfoLinks />
      </div>
    </div>
  );
};
