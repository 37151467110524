import React, {FC} from 'react';
import Select from 'react-select';
import {Controller, useFormContext} from 'react-hook-form';
import {FormError, FormLabel, FormRow} from './Layout';

interface ISelectInputProps {
  options: {value: string; label: string}[];
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const SelectInput: FC<ISelectInputProps> = ({options, label, name, placeholder, disabled, required}) => {
  const form = useFormContext();

  const error = form.formState.errors[name];

  return (
    <FormRow>
      <FormLabel htmlFor={`field-${name}`}>
        {label}:{required && <span className="text-red-600 ml-1">*</span>}
      </FormLabel>

      <Controller
        name={name}
        control={form.control}
        render={({field}) => {
          return (
            <Select
              {...field}
              placeholder={placeholder}
              isDisabled={disabled}
              isClearable
              isSearchable
              options={options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: 16,
                }),
              }}
              value={options.find((c) => c.value === field.value)}
              onChange={(val) => field.onChange(val?.value || '')}
            />
          );
        }}
      />

      <FormError role="alert">{error ? String(error.message) : ''}</FormError>
    </FormRow>
  );
};
