import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {RouterProvider} from 'react-router-dom';
import {QueryClientProvider} from '@tanstack/react-query';
import {queryClient} from './react-sdk/queries';
import pkg from '../package.json';
import {router} from './Routing';

import 'react-toastify/dist/ReactToastify.css';

const App: FC = observer(() => {
  console.log(`App Start ${pkg.version}`, window.location.origin);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
});

export default App;
