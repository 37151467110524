import {cancelEnvelope, getEnvelope, getEnvelopes, getSignatures, ITimeRange, TEnvelopeStatus, VerdocsEndpoint} from '@verdocs/js-sdk';
import {useMutation, useQuery} from '@tanstack/react-query';
import {invalidateQuery} from './utils';

export const useEnvelopeDetail = (envelopeId: string | undefined) =>
  useQuery({
    queryKey: ['envelopes', envelopeId],
    queryFn: () => (envelopeId ? getEnvelope(VerdocsEndpoint.getDefault(), envelopeId) : null),
  });

export interface IListEnvelopesParams {
  view?: 'inbox' | 'sent' | 'action' | 'waiting' | 'completed';
  q?: string;
  status?: TEnvelopeStatus[];
  created_at?: ITimeRange;
  is_owner?: boolean;
  sort_by?: 'name' | 'created_at' | 'updated_at' | 'canceled_at' | 'status';
  template_id?: string;
  ascending?: boolean;
  rows?: number;
  page?: number;
}

export const useEnvelopes = (params: IListEnvelopesParams) =>
  useQuery({
    queryKey: ['envelopes', 'list', params],
    retry: 3,
    refetchInterval: 30000,
    queryFn: () => getEnvelopes(VerdocsEndpoint.getDefault(), params),
    placeholderData: (previousData) => previousData,
  });

export const useCancelEnvelope = () => {
  return useMutation({
    mutationFn: (id: string) => cancelEnvelope(VerdocsEndpoint.getDefault(), id),
    onSuccess: (result) => {
      console.log('Cancel result', result);
      invalidateQuery(['envelopes']);
      invalidateQuery(['envelopes', result.id]);
      invalidateQuery(['envelopes', 'summary']);
    },
  });
};

export const useSignatures = () => useQuery({queryKey: ['signatures'], queryFn: () => getSignatures(VerdocsEndpoint.getDefault())});

export const useTemplateEnvelopes = (id: string) =>
  useQuery({queryKey: [], queryFn: () => getEnvelopes(VerdocsEndpoint.getDefault(), {template_id: id})});
