import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PageTitle} from '../../Components';
import {ActivityBox} from './ActivityBox';
import {URLS} from '../../Routing';

export const RecentActivity: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-0 flex-col">
      <PageTitle>Recent Activity</PageTitle>
      <div className="flex flex-col laptop:flex-row gap-4">
        <ActivityBox
          view="completed"
          title="Recently Completed"
          onViewAll={() => navigate(URLS.ENVELOPES.build('?view=completed'))}
          onViewEnvelope={(id) => navigate(URLS.ENVELOPE_DETAIL.build(id))}
        />
        <ActivityBox
          view="action"
          title="Action Required"
          onViewAll={() => navigate(URLS.ENVELOPES.build('?view=action'))}
          onViewEnvelope={(id) => navigate(URLS.ENVELOPE_DETAIL.build(id))}
        />
        <ActivityBox
          view="waiting"
          title="Waiting on Others"
          onViewAll={() => navigate(URLS.ENVELOPES.build('?view=waiting'))}
          onViewEnvelope={(id) => navigate(URLS.ENVELOPE_DETAIL.build(id))}
        />
      </div>
    </div>
  );
};
