import {z} from 'zod';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {useForm, FormProvider} from 'react-hook-form';
import {changePassword, VerdocsEndpoint} from '@verdocs/js-sdk';
import {FlexFill, FormColumn, FormColumns, HelpDialog, PageTitle, Input, Label} from '../../Components';
import {AppState} from '../../AppState';

const isPasswordComplex = (password: string) => {
  const isUppercase = (ch: string) => /[A-Z]/.test(ch);
  const isLowercase = (ch: string) => /[a-z]/.test(ch);
  const isSpecialChar = (ch: string) => /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/.test(ch);
  let countOfUpperCase = 0,
    countOfLowerCase = 0,
    countOfSpecialChar = 0;
  for (let i = 0; i < password.length; i++) {
    let ch = password.charAt(i);
    if (isUppercase(ch)) countOfUpperCase++;
    else if (isLowercase(ch)) countOfLowerCase++;
    else if (isSpecialChar(ch)) countOfSpecialChar++;
  }
  return password.length >= 8 && countOfLowerCase > 0 && countOfUpperCase > 0 && countOfSpecialChar > 0;
};

const schema = z
  .object({
    password: z.string().min(1, 'Enter your current password').max(30),
    new_password: z.string().min(1, 'Enter your new password').max(30),
    confirm_password: z.string().min(1, 'Confirm your new password'),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    path: ['confirm_password'],
    message: 'Passwords do not match.',
  })
  .refine((data) => isPasswordComplex(data.new_password), {
    path: ['confirm_password'],
    message:
      'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one special character.',
  });

type SchemaType = z.infer<typeof schema>;

export const Security: FC = observer(() => {
  const [submitting, setSubmitting] = useState(false);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {
      password: '',
      new_password: '',
      confirm_password: '',
    },
  });

  const onSubmit = (values: SchemaType) => {
    console.log('Resetting PW', AppState.currentProfile?.email, values);
    setSubmitting(true);
    changePassword(VerdocsEndpoint.getDefault(), {
      old_password: values.password,
      new_password: values.new_password,
    })
      .then((r) => {
        setSubmitting(false);
        console.log('Password changed', r);
        toast.success('Your password has been reset.');
      })
      .catch((e) => {
        setSubmitting(false);
        console.log('Reset error', e);
        toast.error('Password reset failed. Please check your inputs and try again.');
      });
  };

  return (
    <div className="max-w-[640px]">
      <PageTitle>
        Security
        <FlexFill />
        <HelpDialog title="Security">
          <p className="mb-4">Use this form to reset your Verdocs password.</p>
        </HelpDialog>
      </PageTitle>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="my-4 flex flex-col gap-2">
            <Label className="">Current Password</Label>
            <Input type="password" {...form.register('password')} className="w-64" />
          </div>

          <div className="my-4 flex flex-col gap-2">
            <Label className="">New Password</Label>
            <Input type="password" {...form.register('new_password')} className="w-64" />
          </div>

          <div className="my-4 flex flex-col gap-2">
            <Label className="">Confirm New Password</Label>
            <Input type="password" {...form.register('confirm_password')} className="w-64" />
          </div>

          <VerdocsButton type="submit" label="Reset Password" size="normal" disabled={submitting || !form.formState.isValid} />
        </form>
      </FormProvider>
    </div>
  );
});
