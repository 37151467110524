import {Tooltip} from 'react-tooltip';
import {twMerge} from 'tailwind-merge';
import React, {FC, Fragment, PropsWithChildren} from 'react';

const ActiveTabArrow = import.meta.env.BASE_URL + 'assets/tab-right-active.png';
const NormalTabArrow = import.meta.env.BASE_URL + 'assets/tab-right-normal.png';

interface ITab {
  id: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  className?: string;
}

interface ITabsProps {
  tabs: ITab[];
  activeTab: string;
  onSelect: (id: string) => void;
}

const Tab: FC<{activeTab: string; tab: ITab; onSelect: (id: string) => void}> = ({activeTab, tab, onSelect}) => {
  return (
    <div
      id={`tooltip-tab-${tab.id}`}
      className={twMerge(
        'tab-bar-item relative font-bold cursor-pointer bg-[#e7e7e7] h-[50px] items-center',
        activeTab === tab.id ? 'text-[#707ae5] bg-white items-center' : '',
        tab.disabled ? 'cursor-default text-gray-300' : '',
        '[&>img]:hidden [&>img]:tablet:block text-xs tablet:text-sm',
        'px-1 tablet:pr-2 tablet:pl-6 flex flex-1 justify-center',
        tab.className || '',
      )}
      onClick={
        tab.disabled
          ? (e) => {
              e.preventDefault();
            }
          : (e) => {
              e.preventDefault();
              onSelect(tab.id);
            }
      }
      data-tooltip-html={tab.disabled && tab.tooltip && tab.tooltip ? tab.tooltip : undefined}>
      <img
        src={activeTab === tab.id ? ActiveTabArrow : NormalTabArrow}
        aria-hidden
        alt="arrow"
        className="absolute top-0 right-[-19px] z-10"
      />
      <span>{tab.label}</span>
    </div>
  );
};

export const BreadcrumbTabs: FC<PropsWithChildren<ITabsProps>> = ({tabs, activeTab, onSelect, children}) => {
  return (
    <div
      className="flex flex-row tablet:flex-[0_0_50px] flex-1 bg-[#ffffff] items-end drop-shadow text-[#72757d]"
      // style={{color:'#72757d'}}
    >
      {tabs.map((tab, index) => (
        <Fragment key={tab.id}>
          {/*{index > 0 && <div className="font-bold rounded-t-md px-1 pb-4 mb-0.5">...</div>}*/}
          <Tab key={tab.id} tab={tab} activeTab={activeTab} onSelect={onSelect} />
        </Fragment>
      ))}

      {children && (
        <>
          <div className="flex flex-1" />
          <div className="">{children}</div>
        </>
      )}

      <Tooltip anchorSelect=".tab-bar-item" place="bottom" style={{zIndex: 10000}} />
    </div>
  );
};
