import {useState, forwardRef, InputHTMLAttributes} from 'react';
import {EyeOffIcon, EyeIcon} from 'lucide-react';
import {cn} from '../utils';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Input = forwardRef<HTMLInputElement, InputProps>(({className, type, ...props}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const inputClasses = cn(
    'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    // "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
    type === 'password',
    className,
  );

  return (
    <div className={cn('relative', className)}>
      <input type={type === 'password' && showPassword ? 'text' : type} className={inputClasses} ref={ref} {...props} />

      {type === 'password' && (
        <div className="absolute right-0 flex items-center pr-3 -translate-y-1/2 top-1/2 gap-x-1">
          {showPassword ? (
            <EyeIcon className="cursor-pointer text-gray-300 size-5" onClick={togglePasswordVisibility} />
          ) : (
            <EyeOffIcon className="cursor-pointer text-gray-400 size-5" onClick={togglePasswordVisibility} />
          )}
        </div>
      )}
    </div>
  );
});

Input.displayName = 'Input';
