import {format} from 'date-fns';
import {ITemplate} from '@verdocs/js-sdk';
import React, {FC, useState} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FileText, Clipboard, Check} from 'lucide-react';
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, Input} from '../Components';

interface DialogProps {
  template: ITemplate;
}

export const TemplateInfoDialog: FC<DialogProps> = ({template}) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopyTemplateId = () => {
    navigator.clipboard
      .writeText(template.id)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((e) => {
        console.warn('Error copying to clipboard', e);
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <FileText className="size-6 opacity-70 hover:opacity-100" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogDescription />
          <DialogTitle className="mb-4">Template Details</DialogTitle>

          <DialogContent>
            <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Template Details</h1>
            <div className="flex flex-row gap-4 items-center">
              <label className="text-sm font-bold w-20">ID:</label>
              <Input type="text" value={template.id} disabled className="flex flex-1" />
              {copied ? <Check className="size-6  text-green-500" /> : <Clipboard className="size-6" onClick={handleCopyTemplateId} />}
            </div>

            <div className="flex flex-row gap-4 items-center">
              <label className="text-sm font-bold w-20">Name:</label>
              <Input type="text" value={template.name} disabled className="flex flex-1" />
            </div>

            <div className="flex flex-row gap-4 items-center">
              <label className="text-sm font-bold w-20">Visibility:</label>
              <Input type="text" value={template.visibility} disabled className="flex flex-1" />
            </div>

            <div className="flex flex-row gap-4 items-center">
              <label className="text-sm font-bold w-20">Created:</label>
              <Input
                type="text"
                value={template.created_at ? format(new Date(template.created_at), 'P p') : ''}
                disabled
                className="flex flex-1"
              />
            </div>

            <div className="flex flex-row gap-4 items-center">
              <label className="text-sm font-bold w-20">Used:</label>
              <Input type="text" value={`${template.counter} time(s)`} disabled className="flex flex-1" />
            </div>

            <DialogFooter className="mt-8 justify-end">
              <VerdocsButton size="small" label="Done" type="submit" onClick={() => setOpen(false)} />
            </DialogFooter>
          </DialogContent>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
