import {z} from 'zod';
import {toast} from 'react-toastify';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsSpinner} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
import {IProfile, updateOrganizationMember, VerdocsEndpoint} from '@verdocs/js-sdk';
import {getHighestRole, RoleOptions} from '../react-sdk/roles';
import {Button, SelectInput} from '../Components';
import {Dialog} from './Dialog';

const formSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  role_id: z.enum(['owner', 'admin', 'member', 'basic_user', 'contact'], {required_error: 'Please select a role'}),
});

type FormValues = z.infer<typeof formSchema>;

interface DialogProps {
  member: IProfile;
  onClose?: () => void;
}

export const EditMemberDialog: FC<DialogProps> = ({member, onClose = () => {}}) => {
  const [submitting, setSubmitting] = useState(false);

  const highestRole = getHighestRole(member.roles);

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: member.first_name,
      last_name: member.last_name,
      role_id: highestRole || '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    console.log('sub', data);
    setSubmitting(true);
    await updateOrganizationMember(VerdocsEndpoint.getDefault(), member.id, {
      // TODO: If we don't want them editable, remove them from the update endpoint's type
      first_name: data.first_name,
      last_name: data.last_name,
      roles: [data.role_id],
    })
      .then(() => {
        toast.success('Updated member role.');
        setSubmitting(false);
        onClose();
      })
      .catch((e: any) => {
        console.log('Error updating member', e);
        toast.error('Error updating member: ' + e.message);
      });
  };

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Edit {member.email}</h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SelectInput options={RoleOptions} name="role_id" label="Role" />

          <div className="flex flex-row items-center justify-end mt-10 gap-4">
            <Button size="small" variant="secondary" label="Cancel" onClick={onClose} disabled={submitting} />
            <Button size="small" label="OK" type="submit" disabled={submitting || !form.formState.isDirty} />
            {submitting && <VerdocsSpinner mode="dark" />}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
