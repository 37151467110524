import {toast} from 'react-toastify';
import {VerdocsSign} from '@verdocs/web-sdk-react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {getInPersonLink, VerdocsEndpoint} from '@verdocs/js-sdk';
import {FlexFill} from '../../Components';

export const SignInPerson: React.FC = () => {
  const navigate = useNavigate();
  const {id = '', role = ''} = useParams();
  const [inPersonCode, setInPersonCode] = useState('');
  const endpoint = useRef(new VerdocsEndpoint({sessionType: 'signing'}));

  useEffect(() => {
    console.log('Signing in person, getting token...', {id, role});
    getInPersonLink(endpoint.current, id, role)
      .then((r) => {
        console.log('Got in-person link', r);
        setInPersonCode(r.access_key.key);
        endpoint.current.setToken(r.access_token);
      })
      .catch((e) => {
        console.warn(e);
        toast.error('Error obtaining signing token: ' + e.message);
      });
  }, [id, role]);

  return (
    <div className="flex flex-col inset-0 z-50 h-full absolute overscroll-y-auto bg-[#f6f8fa]">
      <button className="top-5 right-4 color-white border-none cursor-pointer absolute bg-transparent" onClick={() => navigate(-1)}>
        <XMarkIcon style={{width: 30}} />
      </button>

      <div id="signing-header" />

      <VerdocsSign
        endpoint={endpoint.current}
        envelopeId={id}
        roleId={role}
        headerTargetId="signing-header"
        inviteCode={inPersonCode}
        onEnvelopeLoaded={(e: any) => {
          console.log('Envelope loaded', e);
        }}
      />

      <FlexFill />
    </div>
  );
};
