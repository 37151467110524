import {format} from 'date-fns';
import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {IEnvelope, ITemplate} from '@verdocs/js-sdk';
import {ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {Button, Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../Components';
import {URLS} from '../../Routing';

const columnHelper = createColumnHelper<IEnvelope>();

const getRecipientFieldValue = (envelope: IEnvelope, roleName: string, fieldName: string) => {
  const recipient = envelope.recipients.find((r) => r.role_name === roleName);
  if (!recipient) {
    return '';
  }

  const field = (envelope.fields || []).find((f) => f.name === fieldName);
  if (!field) {
    return '';
  }

  const {value = '', type} = field || {};
  switch (type) {
    case 'textarea':
    case 'date':
    case 'textbox':
    case 'initial':
    case 'signature':
    case 'attachment':
    case 'dropdown':
    case 'checkbox':
    case 'radio':
      return value;

    // Timestamp fields get automatically filled when the envelope is submitted.
    case 'timestamp':
      return format(new Date(envelope.updated_at), 'P p');
  }

  return '';
};

export const SubmittedDataTable: FC<{template: ITemplate; envelopes: IEnvelope[]}> = observer(({template, envelopes}) => {
  const navigate = useNavigate();
  // REMINDER TO SELF: Do not make query calls inside table components. It creates infinite rendering loops.

  const columns: ColumnDef<IEnvelope, any>[] = [
    columnHelper.accessor('updated_at', {
      header: 'Date Modified',
      cell: (info) => format(new Date(info.getValue()), 'P p'),
    }),
  ];

  (template.roles || []).forEach((role) => {
    (template.fields || [])
      .filter((field) => field.role_name === role.name)
      .forEach((field) => {
        columns.push(
          columnHelper.display({
            id: field.name,
            header: field.name,
            meta: {role: role.name, field: field.name},
            cell: (props) => (
              <div>
                {getRecipientFieldValue(
                  props.row.original,
                  (props.column.columnDef.meta as Record<string, string>)?.role || '',
                  (props.column.columnDef.meta as Record<string, string>)?.field || '',
                )}
              </div>
            ),
          }),
        );
      });
  });

  const table = useReactTable({data: envelopes, columns, getCoreRowModel: getCoreRowModel()});

  return (
    <div className="mt-4 overflow-x-scroll">
      {table.getRowModel().rows.length < 1 ? (
        <div className="flex-col">
          <p className="mb-4">This template has not yet been used to send an Envelope. Get started by clicking the button below!</p>
          <Button label="Send Envelope" onClick={() => navigate(URLS.TEMPLATE_DETAIL.build(template.id, 'preview'))} />
        </div>
      ) : (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="border-b border-b-gray-500">
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="whitespace-nowrap pr-2 text-left">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="whitespace-nowrap pr-2 py-1">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {/*<TableRow>*/}
            {/*  <TableCell className="font-medium">INV001</TableCell>*/}
            {/*  <TableCell>Paid</TableCell>*/}
            {/*  <TableCell>Credit Card</TableCell>*/}
            {/*  <TableCell className="text-right">$250.00</TableCell>*/}
            {/*</TableRow>*/}
          </TableBody>
        </Table>
      )}
    </div>
  );
});
