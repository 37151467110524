import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {InboxIcon} from '@heroicons/react/24/solid';
import {TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline';
import {IProfile, VerdocsEndpoint, deleteOrganizationContact} from '@verdocs/js-sdk';
import {Spinner, Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../../Components';
import {useOrganizationContacts} from '../../../react-sdk/queries';
import {EditContactDialog, OkCancelDialog} from '../../../Dialogs';
import {AppState} from '../../../AppState';

export const ContactsTable: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id;

  const [working, setWorking] = React.useState(false);
  const [deletingContactId, setDeletingContactId] = useState('');
  const [editingContact, setEditingContact] = useState<IProfile | null>(null);
  const contactsQuery = useOrganizationContacts(organizationId);
  const contacts = contactsQuery.data || [];

  if (contactsQuery.isLoading) {
    return <Spinner mode="dark" />;
  }

  if (contactsQuery.isError) {
    return <div className="">Unable to load Organization contacts. Please try again later.</div>;
  }

  const handleDeleteContact = async () => {
    setWorking(true);
    try {
      await deleteOrganizationContact(VerdocsEndpoint.getDefault(), deletingContactId);
      contactsQuery.refetch().catch((e: any) => console.log('Unknown Error', e));
      toast.info('Contact deleted.');
    } catch (e) {
      console.log('e', e);
      toast.error('Error deleting contact, please try again later.');
    }
    setDeletingContactId('');
    setWorking(false);
  };

  if (!contacts.length) {
    return (
      <p>
        Contacts are special organization contacts that may not login or perform other functions. They appear only in the Contact Picker when
        creating/sending envelopes.
      </p>
    );
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="pl-0 tablet:pl-4">Name</TableHead>
            <TableHead className="hidden tablet:table-cell">Email</TableHead>
            <TableHead className="hidden tablet:table-cell">Phone</TableHead>
            <TableHead className="hidden tablet:table-cell text-center">Actions</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell className="pl-0 tablet:pl-4">
                <div className="flex flex-row gap-2 items-center overflow-hidden truncate">
                  {`${contact.first_name} ${contact.last_name}`}
                </div>
              </TableCell>
              <TableCell className="hidden tablet:table-cell truncate">
                <InboxIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {contact.email}
              </TableCell>
              <TableCell className="hidden tablet:table-cell truncate">
                <InboxIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {contact.phone}
              </TableCell>
              <TableCell className="hidden tablet:table-cell text-center">
                <div className="flex flex-row gap-2">
                  <TrashIcon
                    className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer"
                    onClick={() => setDeletingContactId(contact.id)}
                  />
                  <PencilSquareIcon
                    className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer"
                    onClick={() => setEditingContact(contact)}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {deletingContactId && (
        <OkCancelDialog onCancel={() => setDeletingContactId('')} onOk={handleDeleteContact}>
          Are you sure you want to delete this contact? This action cannot be undone.
        </OkCancelDialog>
      )}

      {editingContact && (
        <EditContactDialog
          contact={editingContact}
          onClose={() => {
            setEditingContact(null);
            contactsQuery.refetch();
          }}
        />
      )}

      {working && (
        <div className="absolute inset-0 bg-[#0000007f] flex flex-col items-center pt-8 text-white font-lg font-semibold">
          <Spinner mode="light" />
          <div className="mt-4">Please wait...</div>
        </div>
      )}
    </>
  );
});
