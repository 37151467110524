import {z} from 'zod';
import React, {FC} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
import {createOrganization, VerdocsEndpoint} from '@verdocs/js-sdk';
import {FlexFill, FormColumn, FormColumns, HelpDialog, PageTitle, TextInput} from '../../Components';
import {useNavigate} from 'react-router-dom';

const schema = z.object({
  name: z.string().trim().min(3, 'Name is required').max(30),
});

type SchemaType = z.infer<typeof schema>;

export const CreateOrg: FC = observer(() => {
  const navigate = useNavigate();
  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {name: ''},
  });

  const onSubmit = async (data: any) => {
    await createOrganization(VerdocsEndpoint.getDefault(), data)
      .then((r) => {
        VerdocsEndpoint.getDefault().setToken(r.access_token);
        navigate('/', {replace: true});
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((e) => toast.error(`Error creating organization: ${e.message}`));
  };

  const dirty = form.formState.isDirty;

  return (
    <div className="p-8 w-full max-w-[640px]">
      <PageTitle>
        Create Organization
        <FlexFill />
        <HelpDialog title="About Organizations">
          <p>
            An "Organization" in Verdocs is a group of members and their templates and envelopes. Activity in each organization is isolated
            and not visible to others. Each organization may also have its own billing plan and feature entitlements.
          </p>
        </HelpDialog>
      </PageTitle>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormColumns>
            <FormColumn className="flex-[2]">
              <p className="mb-4">
                Create a new organization to manage Templates and Envelopes separately. You will automatically be made the owner of the new
                organization.
              </p>

              <TextInput label="Name" name="name" required />
            </FormColumn>
          </FormColumns>

          <VerdocsButton type="submit" label="Save" size="normal" disabled={form.formState.isSubmitting || !dirty} />
        </form>
      </FormProvider>
    </div>
  );
});
