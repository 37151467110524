import React, {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {FormError, FormLabel, FormRow} from './Layout';

interface ITextInputProps {
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const TextInput: FC<ITextInputProps> = ({label, name, type = 'text', placeholder, disabled, required}) => {
  const form = useFormContext();
  const error = form.formState.errors[name];

  return (
    <FormRow>
      {label && (
        <FormLabel htmlFor={`field-${name}`}>
          {label}:{required && <span className="text-red-600 ml-1">*</span>}
        </FormLabel>
      )}

      <input
        type={type}
        disabled={disabled}
        required={required}
        {...form.register(name)}
        placeholder={placeholder}
        aria-invalid={error !== undefined ? 'true' : 'false'}
        className="text-sm pt-0.5 pl-2.5 flex-[0_0_40px] rounded-lg border border-[#707ae5] disabled:opacity-70"
      />
      <FormError role="alert">{error ? String(error.message) : ''}</FormError>
    </FormRow>
  );
};
