import {QueryCache, QueryClient} from '@tanstack/react-query';
import {persistQueryClient} from '@tanstack/react-query-persist-client';
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      console.warn('Query error', error);
    },
  }),
  defaultOptions: {
    queries: {
      retry: 1,
      gcTime: 24 * 60 * 60 * 1000, // 24h
      staleTime: 300000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

export const clearQueryCache = () => {
  queryClient.clear();
}

const persister = createSyncStoragePersister({storage: window.localStorage});
persistQueryClient({queryClient, persister});
