import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {CalendarDaysIcon, TrashIcon} from '@heroicons/react/24/outline';
import {InboxIcon, ArrowPathIcon, ClockIcon} from '@heroicons/react/24/solid';
import {deleteApiKey, IApiKey, rotateApiKey, VerdocsEndpoint} from '@verdocs/js-sdk';
import {invalidateQuery, useApiKeys} from '../../../react-sdk/queries';
import {OkCancelDialog} from '../../../Dialogs';
import {AppState} from '../../../AppState';
import {
  DataTable,
  DataTableBody,
  DataTableDataCell,
  DataTableHead,
  DataTableHeaderCell,
  DataTableHeaderRow,
  DataTableRow,
  Spinner,
} from '../../../Components';

export const ApiKeysTable: FC<{onRegenerate: (key: IApiKey) => void}> = observer(({onRegenerate}) => {
  const organizationId = AppState.currentProfile?.organization_id;

  const [working, setWorking] = React.useState(false);
  const [confirming, setConfirming] = useState<IApiKey | null>(null);
  const apiKeysQuery = useApiKeys(organizationId);
  const apiKeys = apiKeysQuery.data || [];

  const handleRegenerate = () => {
    if (confirming) {
      setWorking(true);
      rotateApiKey(VerdocsEndpoint.getDefault(), confirming.client_id)
        .then((r) => {
          setWorking(false);
          console.log('Regenerated key', r);
          onRegenerate(r);
        })
        .catch((e) => {
          setWorking(false);
          setConfirming(null);
          console.log('Error regenerating key', e);
          toast.error('Unable to regenerate key, please try again later.');
        });
    }

    setConfirming(null);
  };

  const handleDelete = (key: IApiKey) => {
    setWorking(true);
    deleteApiKey(VerdocsEndpoint.getDefault(), key.client_id)
      .then((r) => {
        setWorking(false);
        toast.success('The API key has been deleted.');
        invalidateQuery(['api_keys']);
      })
      .catch((e) => {
        setWorking(false);
        console.log('Delete error', e);
        toast.error('There was an error deleting the API key. Please try again later.');
      });
  };

  return (
    <>
      <DataTable>
        <DataTableHead>
          <DataTableHeaderRow>
            <DataTableHeaderCell>Name</DataTableHeaderCell>
            <DataTableHeaderCell className="hidden tablet:table-cell">Access</DataTableHeaderCell>
            <DataTableHeaderCell className="hidden desktop:table-cell">Owner</DataTableHeaderCell>
            <DataTableHeaderCell className="text-center w-[110px]">Actions</DataTableHeaderCell>
          </DataTableHeaderRow>
        </DataTableHead>
        <DataTableBody>
          {apiKeys.map((key) => (
            <DataTableRow key={key.client_id}>
              <DataTableDataCell>
                <ClockIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline truncate" />
                {key.name}
              </DataTableDataCell>
              <DataTableDataCell className="hidden tablet:table-cell truncate">
                <InboxIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {key.global_admin ? 'Global Admin' : 'Personal'}
              </DataTableDataCell>
              <DataTableDataCell className="hidden desktop:table-cell truncate">
                <CalendarDaysIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {key.profile?.first_name} {key.profile?.last_name}
              </DataTableDataCell>
              <DataTableDataCell>
                <div className="flex flex-row gap-4">
                  <TrashIcon className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer" onClick={() => handleDelete(key)} />
                  <ArrowPathIcon
                    className="w-6 h-6 text-gray-400 mr-3 hover:text-blue-600 cursor-pointer"
                    onClick={() => setConfirming(key)}
                  />
                </div>
              </DataTableDataCell>
            </DataTableRow>
          ))}
        </DataTableBody>
      </DataTable>

      {confirming && (
        <OkCancelDialog onOk={handleRegenerate} onCancel={() => setConfirming(null)}>
          <p>NOTE: Regenerating the secret for this key will invalidate all future requests using the old secret. Continue?</p>
        </OkCancelDialog>
      )}

      {working && (
        <div className="absolute inset-0 bg-[#0000007f] flex flex-col items-center pt-8 text-white font-lg font-semibold">
          <Spinner mode="light" />
          <div className="mt-4">Please wait...</div>
        </div>
      )}
    </>
  );
});
