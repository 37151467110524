import {useQuery} from '@tanstack/react-query';
import {
  getGroups,
  getOrganizationInvitations,
  getOrganizationContacts,
  getOrganizationMembers,
  getOrganizationInvitation,
  getWebhooks,
  VerdocsEndpoint,
  getOrganization,
} from '@verdocs/js-sdk';

export const useOrganization = (organizationId: string) =>
  useQuery({
    queryKey: ['organization', organizationId],
    queryFn: () => getOrganization(VerdocsEndpoint.getDefault(), organizationId),
  });

export const useOrganizationGroups = () =>
  useQuery({
    queryKey: ['organization_groups'],
    queryFn: () => getGroups(VerdocsEndpoint.getDefault()),
  });

export const useOrganizationMembers = (organizationId: string | undefined) =>
  useQuery({
    queryKey: ['organization_members', organizationId],
    queryFn: () => (organizationId ? getOrganizationMembers(VerdocsEndpoint.getDefault()) : null),
  });

export const useOrganizationContacts = (organizationId: string | undefined) =>
  useQuery({
    queryKey: ['organization_contacts', organizationId],
    queryFn: () => (organizationId ? getOrganizationContacts(VerdocsEndpoint.getDefault()) : null),
  });

export const useOrganizationInvites = (organizationId: string | undefined) =>
  useQuery({
    queryKey: ['organization_invites', organizationId],
    queryFn: () => (organizationId ? getOrganizationInvitations(VerdocsEndpoint.getDefault()) : null),
  });

export const useInvitation = (email: string, token: string) =>
  useQuery({
    queryKey: ['invite', email, token],
    queryFn: () => getOrganizationInvitation(VerdocsEndpoint.getDefault(), email, token),
  });

export const useOrganizationWebhook = () =>
  useQuery({
    queryKey: ['organization_webhook'],
    queryFn: () => getWebhooks(VerdocsEndpoint.getDefault()),
    staleTime: 0,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
