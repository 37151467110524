import {QueryKey} from '@tanstack/react-query';
import {queryClient} from './queryClient';

// This deliberately does not return. Invalidating is a semi-optional operation that fails safe since we dialled down the
// cache and refetch intervals making the UI's data more self-healing. There's really no good action/response to a clear
// operation "failing" so returning that event to the caller (which itself is almost certainly an API call return handler)
// is meaningless.
export const invalidateQuery = (queryKey: QueryKey) => {
  queryClient.invalidateQueries({queryKey}).catch(() => window.console.log('[API] Error invalidating query', queryKey));
};

export const invalidateAllQueries = () => {
  queryClient.invalidateQueries().catch(() => window.console.log('[API] Error invalidating queries'));
};
