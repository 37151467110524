import {observer} from 'mobx-react-lite';
import React, {FC, useEffect} from 'react';
import {StandardView} from '../../Components';
import {TemplateSummary} from './TemplateSummary';

export const Templates: FC = observer(() => {
  // TODO: Get a better component in there
  useEffect(() => {
    document.getElementById('verdocs-dropdown-menu-items')?.remove();
  });

  return (
    <StandardView>
      <TemplateSummary rowsPerPage={20} />
    </StandardView>
  );
});
