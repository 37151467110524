import React, {FC} from 'react';
import {capitalize, formatShortTimeAgo, integerSequence} from '@verdocs/js-sdk';
import NoEnvelopes from '../../assets/no-envelopes.png';
import {useEnvelopes} from '../../react-sdk/queries';
import {Button} from '../../Components';
import './ActivityBox.css';

const ITEM_COUNT = 5;

interface IActivityBoxProps {
  view: 'completed' | 'action' | 'waiting';
  title: string;
  onViewAll: () => void;
  onViewEnvelope: (id: string) => void;
}

export const ActivityBox: FC<IActivityBoxProps> = ({view, title, onViewAll, onViewEnvelope}) => {
  const {data, isLoading} = useEnvelopes({view, rows: 5});

  if (isLoading || !data) {
    return (
      <div className="activity-box" style={{minHeight: '300px'}}>
        <div className="box-title">
          {title} <span className="count">(0)</span>
        </div>

        {integerSequence(0, ITEM_COUNT).map((_, index) => (
          <div className="loading-placeholder" key={index}>
            <div className="loading-placeholder-bg"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="activity-box">
      <div className="box-title">
        {title} <span className="count">({data.count})</span>
        <div className="spacer" />
        <Button size="xsmall" label="View All" onClick={onViewAll} />
      </div>

      {data.count > 0 ? (
        data.envelopes.map((envelope) => {
          const nextRecipient =
            envelope.recipients.filter((recipient) => !['declined', 'canceled'].includes(recipient.status))[0] || envelope.recipients[0];

          return (
            <div
              className="w-full flex flex-row text-sm py-1.5 px-3 bg-white border border-transparent hover:cursor-pointer hover:bg-[#f2f4f5] hover:border hover:border-gray-300"
              key={envelope.id}
              onClick={() => onViewEnvelope(envelope.id)}>
              <div className="flex flex-1 flex-col">
                <div className="font-semibold">{envelope.name}</div>
                <div>
                  {capitalize(`${nextRecipient?.full_name}`)}: <span>{capitalize(nextRecipient?.status || '')}</span>
                </div>
              </div>

              {/*<Tooltip content={capitalize(formatRelative(new Date(envelope.updated_at), new Date()))}>*/}
              <div className="flex flex-[0_0_40px] w-[40px] pl-4 justify-center items-center border-l box-border my-2">
                {formatShortTimeAgo(new Date(envelope.updated_at))}
              </div>
              {/*</Tooltip>*/}
            </div>
          );
        })
      ) : (
        <div className="flex flex-col h-full items-center justify-center">
          <img src={NoEnvelopes} alt="No envelopes to show" className="w-28 m-auto my-4" />
          <div className="text-sm text-[#828391] mb-8">There are no envelopes to show.</div>
        </div>
      )}
    </div>
  );
};
