import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {useFormContext} from 'react-hook-form';
import {Divider, ToggleGroup, ToggleGroupItem} from '../../../Components';

const Item = ({children, value, disabled = false}: {children: any; value: string; disabled?: boolean}) => (
  <ToggleGroupItem value={value} className="leading-1 h-6 bg-gray-200 text-xs data-[state=on]:bg-[#654dcb] data-[state=on]:text-white">
    {children}
  </ToggleGroupItem>
);

export const PermissionsGrid: FC<{name: string}> = observer(({name}) => {
  const form = useFormContext();
  const [localPermissions, setLocalPermissions] = React.useState(form.getValues(name));

  const handleSetPermissions = (permissions: any[]) => {
    setLocalPermissions(permissions);
    form.setValue(name, permissions, {shouldDirty: true});
  };

  return (
    <ToggleGroup
      type="multiple"
      className="flex-row flex-wrap justify-start gap-2 max-h-64 overflow-y-scroll"
      value={localPermissions}
      onValueChange={handleSetPermissions}>
      <div className="flex flex-0 w-full p-0 m-0 text-sm font-medium">Create Templates</div>
      <Divider className="my-0 h-0" />

      <Item value="template:creator:create:personal">Private</Item>
      <Item value="template:creator:create:org">Shared</Item>
      <Item value="template:creator:create:public">Public</Item>

      <div className="flex flex-0 w-full p-0 m-0 mt-3 text-sm font-medium">Shared Templates</div>
      <Divider className="my-0 h-0" />

      <Item value="template:member:read">View</Item>
      <Item value="template:member:write">Edit</Item>
      <Item value="template:member:delete">Delete</Item>
      <Item value="template:member:visibility">Change Visibility</Item>

      <div className="flex flex-0 w-full p-0 m-0 mt-3 text-sm font-medium">Envelopes</div>
      <Divider className="my-0 h-0" />
      <Item value="envelope:create">Create</Item>
      <Item value="envelope:cancel">Cancel</Item>
      <Item value="envelope:view">View</Item>

      <div className="flex flex-0 w-full p-0 m-0 mt-3 text-sm font-medium">Organization Members</div>
      <Divider className="my-0 h-0" />

      <Item value="owner:add">Assign 'Owner'</Item>
      <Item value="owner:remove">Remove 'Owner'</Item>
      <Item value="admin:add">Assign 'Admin'</Item>
      <Item value="admin:remove">Remove 'Admin'</Item>
      <Item value="member:add">Assign 'Member'</Item>
      <Item value="member:remove">Remove 'Member'</Item>

      <div className="flex flex-0 w-full p-0 m-0 mt-3 text-sm font-medium">Organization Settings</div>
      <Divider className="my-0 h-0" />

      <Item value="org:update">Update</Item>
      <Item value="org:delete">Delete</Item>
    </ToggleGroup>
  );
});
