import React, {FC} from 'react';
import {twMerge} from 'tailwind-merge';
import {UserCircleIcon} from '@heroicons/react/24/outline';
import {PencilSquareIcon} from '@heroicons/react/24/solid';

export const PhotoSelector: FC<{
  value: string | null | undefined;
  newFile: File | null;
  rounded?: boolean;
  onSelectFile: (file: File) => void;
}> = ({newFile, value, onSelectFile, rounded = false}) => {
  const containerId = `verdocs-photo-picker-${Math.random().toString(36).substring(2, 11)}`;

  const handleSelectPhoto = (event: any) => {
    onSelectFile(event.target.files[0]);
  };

  const handlePhotoUpload = () => {
    document.getElementById(containerId)?.click();
  };

  const renderImage = () => {
    if (newFile) {
      return (
        <div
          className={twMerge(
            'relative bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:16px_16px] overflow-hidden size-24 flex items-center justify-center',
            rounded ? 'rounded-xl' : '',
          )}>
          <img
            src={URL.createObjectURL(newFile)}
            className={twMerge('object-contain w-[auto] h-[auto] m-[auto]', rounded ? 'rounded-full' : '')}
            alt="Image"
          />
          <PencilSquareIcon className="size-6 absolute bottom-1 right-1 stroke-gray-200 fill-gray-500" />
        </div>
      );
    }

    if (value) {
      return (
        <div
          className={twMerge(
            'relative bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:16px_16px] overflow-hidden size-24 flex items-center justify-center',
            rounded ? 'rounded-xl' : '',
          )}>
          <img src={value} className="object-contain w-[auto] h-[auto] m-[auto]" alt="Image" />
          <PencilSquareIcon className="size-6 absolute bottom-0 right-0 stroke-gray-200 fill-gray-500" />
        </div>
      );
    }

    return (
      <div
        className={twMerge(
          'relative bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:16px_16px] overflow-hidden size-24 flex items-center justify-center',
          rounded ? 'rounded-xl' : '',
        )}>
        <UserCircleIcon className="size-24 stroke-gray-400" />
        <PencilSquareIcon className="size-6 absolute bottom-1 right-1 stroke-gray-200 fill-gray-500" />
      </div>
    );
  };

  return (
    <div className="flex flex-0 flex-col">
      <input type="file" id={containerId} className="hidden" onChange={handleSelectPhoto} />

      <div className="cursor-pointer" onClick={handlePhotoUpload}>
        {renderImage()}
      </div>
    </div>
  );
};
