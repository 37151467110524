import styled from '@emotion/styled';
import React, {FC, PropsWithChildren} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';

const Background = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #0000007f;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  padding: 30px 20px 20px;
  width: 500px;
  display: flex;
  max-width: 100%;
  border-radius: 6px;
  background: #ffffff;
  font-size: 18px;
	flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  margin: 40px 0 0 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  verdocs-button {
    margin-left: 10px;
  }
`;

interface OkCancelDialogProps {
  onOk: () => void;
  onCancel?: () => void;
}

export const OkCancelDialog: FC<PropsWithChildren<OkCancelDialogProps>> = ({children, onOk, onCancel = () => {}}) => {
  return (
    <Background>
      <Content>
        {children}

        <Buttons>
          <VerdocsButton size="small" variant="outline" label="Cancel" onClick={onCancel} />
          <VerdocsButton size="small" label="OK" onClick={onOk} />
        </Buttons>
      </Content>
    </Background>
  );
};
