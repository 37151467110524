import {z} from 'zod';
import {toast} from 'react-toastify';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {VerdocsButton, VerdocsSpinner} from '@verdocs/web-sdk-react';
import {createOrganizationContact, VerdocsEndpoint} from '@verdocs/js-sdk';
import {TextInput} from '../Components';
import {Dialog} from './Dialog';

const schema = z.object({
  email: z.string().trim().email('Invalid email').min(1, 'Email is required'),
  first_name: z.string().trim().min(1, 'First name is required'),
  last_name: z.string().trim().min(1, 'Last name is required'),
  phone: z.string().nullable(),
});

type SchemaType = z.infer<typeof schema>;

interface AddUserDialogProps {
  onClose?: () => void;
}

export const AddContactDialog: FC<AddUserDialogProps> = ({onClose = () => {}}) => {
  const [submitting, setSubmitting] = useState(false);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {email: '', first_name: '', last_name: '', phone: ''},
  });

  const onSubmit = (data: any) => {
    console.log('sub', data);
    setSubmitting(true);
    return createOrganizationContact(VerdocsEndpoint.getDefault(), data)
      .then((r) => {
        console.log('r', r);
        setSubmitting(false);
        form.reset(r);
        toast.success('Contact added.');
        onClose();
      })
      .catch((e) => {
        console.log('e', e);
        setSubmitting(false);
        toast.error(`Error adding contact: ${e.response?.data?.error || e.message}`);
      });
  };

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Add New Contact</h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <TextInput label="Email Address" name="email" required />
          <TextInput label="First Name" name="first_name" required />
          <TextInput label="Last Name" name="last_name" required />
          <TextInput label="Phone Number" name="phone" />

          <div className="flex flex-row items-center justify-end mt-10 gap-4">
            <VerdocsButton size="small" variant="outline" label="Cancel" onClick={onClose} disabled={submitting} />
            <VerdocsButton size="small" label="OK" type="submit" disabled={submitting} />
            {submitting && <VerdocsSpinner mode="dark" />}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
