import tw from 'tailwind-styled-components';

// Fill the space between flex-box items
export const FlexFill = tw.div`flex-1`;

// Standard view and content box for most pages
export const StandardView = tw.div`flex flex-1 flex-col p-4 tablet:p-7 bg-[#f2f5fa] box-border overflow-y-scroll`;
export const NonScrollingView = tw.div`flex flex-1 flex-col p-4 tablet:p-7 bg-[#f2f5fa] box-border overflow-y-hidden`;
export const Content = tw.div`flex-1 w-full h-full flex bg-[#f2f5fa] flex-col`;

// Standard and alternate title options
export const PageTitle = tw.h1`text-2xl desktop:text-3xl mb-3 desktop:mb-4 flex flex-row`;
export const SubTitle = tw.h1`sans-serif font-bold text-2xl text-[#33364b] mb-10`;
// export const DialogTitle = tw.h1`sans-serif font-bold text-xl text-[#33364b] mb-4`;
export const Divider = tw.hr`border-t border-top-gray-300 my-5 w-full`;

// Various form layout helpers
export const FormColumns = tw.div`flex flex-col tablet:flex-row gap-0 tablet:gap-12 mb-3`;
export const FormColumn = tw.div`flex flex-1 flex-col`;
export const FormRow = tw.div`flex flex-0 flex-col mb-4`;
export const FormLabel = tw.label`color-[#5c6575] mb-2 text-sm font-medium`;
export const FormError = tw.p`text-red-600 text-md`;

// Various data table layout helpers
export const DataTable = tw.table`w-full border-collapse border-b border-b-gray-200 table-fixed`;
export const DataTableHead = tw.thead``;
export const DataTableBody = tw.tbody``;
export const DataTableHeaderRow = tw.tr``;
export const DataTableRow = tw.tr`my-1 border-t border-t-gray-200 bg-white py-2 px-5`;
// export const DataTableRow = tw.tr`my-1 border border-gray-300 bg-white py-2 px-5`;
export const DataTableHeaderCell = tw.td`whitespace-nowrap py-2 px-4 text-left font-semibold text-[#5c6575]`;
export const DataTableDataCell = tw.td`whitespace-nowrap py-2 px-4`;
