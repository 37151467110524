import ReactGA from 'react-ga4';

export const AnalyticsManager = {
  // Initialize the trackers
  init() {
    ReactGA.initialize('G-2VG6MM1FC6');
  },

  // Record a page view
  recordPageView(page: string, title: string) {
    ReactGA.send({hitType: 'pageview', page, title});
  },
};
