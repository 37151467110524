import {z} from 'zod';
import React, {FC, useState} from 'react';
import {toast} from 'react-toastify';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {VerdocsSpinner} from '@verdocs/web-sdk-react';
import {IProfile, updateOrganizationContact, VerdocsEndpoint} from '@verdocs/js-sdk';
import {Button, TextInput} from '../Components';
import {Dialog} from './Dialog';

const formSchema = z.object({
  first_name: z.string({required_error: 'Please enter a first name'}),
  last_name: z.string({required_error: 'Please enter a first name'}),
  email: z.string({required_error: 'Please enter an email address'}).email(),
  phone: z.string().nullable(),
});

type FormValues = z.infer<typeof formSchema>;

interface DialogProps {
  contact: IProfile;
  onClose?: () => void;
}

export const EditContactDialog: FC<DialogProps> = ({contact, onClose = () => {}}) => {
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<FormValues>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: contact.first_name || '',
      last_name: contact.last_name || '',
      email: contact.email || '',
      phone: contact.phone || '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    console.log('sub', data);
    setSubmitting(true);
    await updateOrganizationContact(VerdocsEndpoint.getDefault(), contact.id, data)
      .then(() => {
        toast.success('Updated contact.');
        setSubmitting(false);
        onClose();
      })
      .catch((e: any) => {
        console.log('Error updating contact', e);
        toast.error('Error updating contact: ' + e.message);
      });

    toast.success('Updated contact.');
    onClose();
  };

  return (
    <Dialog onCancel={onClose}>
      <h1 className="sans-serif font-bold text-xl text-[#33364b] mb-4">Edit Contact {contact.email}</h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <TextInput label="First" placeholder="First name..." {...form.register('first_name')} required />
          <TextInput label="Last" placeholder="Last name..." {...form.register('last_name')} required />
          <TextInput label="Email" placeholder="Email address..." {...form.register('email')} required />
          <TextInput label="Phone" placeholder="Optional phone number..." {...form.register('phone')} />

          <div className="flex flex-row items-center justify-end mt-10 gap-4">
            <Button size="small" variant="secondary" label="Cancel" onClick={onClose} disabled={false} />
            <Button size="small" label="OK" type="submit" disabled={!form.formState.isDirty} />
            {submitting && <VerdocsSpinner mode="dark" />}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
