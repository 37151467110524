import React from 'react';
import {createBrowserRouter, Navigate} from 'react-router-dom';
import * as EnvelopeViews from '../Views/Envelopes';
import * as TemplateViews from '../Views/Templates';
import * as SettingsViews from '../Views/Settings';
import * as AnonViews from '../Views/Anonymous';
import * as EmbedViews from '../Views/Embeds';
import * as LoginViews from '../Views/Login';
import * as MainViews from '../Views/Main';
import * as SignViews from '../Views/Sign';
import {URLS} from './urls';
import {Root} from './Root';

// react-router-dom still doesn't allow metadata to be attached to routes
export const AnonymousRoutes = [
  'Login',
  'Signup',
  'OAuth2Authorize',
  'SignAnonymously',
  'SignAnonymouslyOld',
  'NotFound',
  'AcceptInvite',

  'EmbedAuth',
  'EmbedSign',
  'EmbedBuild',
  'EmbedPreview',
  'EmbedView',
  'EmbedSend',
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {id: 'Dashboard', index: true, element: <MainViews.Dashboard />},
      {id: 'CreateOrg', path: URLS.CREATE_ORG.route, element: <MainViews.CreateOrg />},

      {id: 'Login', path: URLS.LOGIN.route, element: <LoginViews.Login />},
      {id: 'Signup', path: URLS.SIGNUP.route, element: <LoginViews.Signup />},
      {id: 'OAuth2Authorize', path: URLS.AUTHORIZE_OAUTH2.route, element: <LoginViews.OAuth2Authorize />},
      {id: 'AcceptInvite', path: URLS.ACCEPT_INVITE.route, element: <LoginViews.AcceptInvite />},

      {id: 'SignAnonymously', path: URLS.SIGN_ANONYMOUSLY.route, element: <SignViews.SignAnonymously />},
      {id: 'SignAnonymouslyOld', path: URLS.SIGN_ANONYMOUSLY_OLD.route, element: <SignViews.SignAnonymously />},
      {id: 'SignInPerson', path: URLS.SIGN_IN_PERSON.route, element: <SignViews.SignInPerson />},

      {id: 'Envelopes', path: URLS.ENVELOPES.route, element: <EnvelopeViews.Envelopes />},
      {id: 'EnvelopeDetail', path: URLS.ENVELOPE_DETAIL.route, element: <EnvelopeViews.EnvelopeDetail />},
      {id: 'EnvelopeFinish', path: URLS.ENVELOPE_FINISH.route, element: <EnvelopeViews.EnvelopeFinish />},

      {id: 'TemplateDetail', path: URLS.TEMPLATE_DETAIL.route, element: <TemplateViews.TemplateDetail />},
      {id: 'Templates', path: URLS.TEMPLATES.route, element: <TemplateViews.Templates />},
      {id: 'NewTemplate', path: URLS.NEW_TEMPLATE.route, element: <TemplateViews.NewTemplate />},
      {id: 'SubmittedData', path: URLS.SUBMITTED_DATA.route, element: <TemplateViews.SubmittedData />},

      {id: 'EmbedAuth', path: URLS.EMBEDS_AUTH.route, element: <EmbedViews.Auth />},
      {id: 'EmbedBuild', path: URLS.EMBEDS_BUILD.route, element: <EmbedViews.Build />},
      {id: 'EmbedPreview', path: URLS.EMBEDS_PREVIEW.route, element: <EmbedViews.Preview />},
      {id: 'EmbedSend', path: URLS.EMBEDS_PREVIEW.route, element: <EmbedViews.Send />},
      {id: 'EmbedSign', path: URLS.EMBEDS_SIGN.route, element: <EmbedViews.Sign />},
      {id: 'EmbedView', path: URLS.EMBEDS_VIEW.route, element: <EmbedViews.View />},

      {
        id: 'Settings',
        path: URLS.SETTINGS_BASE.route,
        element: <SettingsViews.Settings />,
        children: [
          {id: 'SettingsBase', index: true, element: <Navigate to="profile" replace={true} />},
          {id: 'SettingsProfile', path: URLS.SETTINGS_PROFILE.route, element: <SettingsViews.Profile />},
          {id: 'SettingsSecurity', path: URLS.SETTINGS_SECURITY.route, element: <SettingsViews.Security />},
          {id: 'SettingsSignature', path: URLS.SETTINGS_SIGNATURE.route, element: <SettingsViews.Signature />},
          {id: 'SettingsOrganization', path: URLS.SETTINGS_ORGANIZATION.route, element: <SettingsViews.Organization />},
          {id: 'SettingsDelete', path: URLS.SETTINGS_DELETEORG.route, element: <SettingsViews.DeleteOrg />},
          {id: 'SettingsMembers', path: URLS.SETTINGS_MEMBERS.route, element: <SettingsViews.Members />},
          {id: 'SettingsGroups', path: URLS.SETTINGS_GROUPS.route, element: <SettingsViews.Groups />},
          {id: 'SettingsUsage', path: URLS.SETTINGS_USAGE.route, element: <SettingsViews.OrgUsage />},
          {id: 'SettingsApiKeys', path: URLS.SETTINGS_API_KEYS.route, element: <SettingsViews.ApiKeys />},
          {id: 'SettingsWebhooks', path: URLS.SETTINGS_WEBHOOKS.route, element: <SettingsViews.Webhooks />},
        ],
      },

      {id: 'NotFound', path: '*', element: <AnonViews.NotFound />},
    ],
  },
]);
