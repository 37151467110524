import React, {useRef} from 'react';
import {useParams} from 'react-router-dom';
import {VerdocsEndpoint} from '@verdocs/js-sdk';
import {VerdocsSign} from '@verdocs/web-sdk-react';
import {FlexFill} from '../../Components';

export const SignAnonymously: React.FC = () => {
  const {id = '', role = '', code = ''} = useParams();
  const endpoint = useRef(new VerdocsEndpoint({sessionType: 'signing'}));

  return (
    <div className="flex flex-col inset-0 z-50 h-full absolute overscroll-y-auto bg-[#f6f8fa]">
      <VerdocsSign
        endpoint={endpoint.current}
        envelopeId={id}
        roleId={role}
        inviteCode={code}
        onEnvelopeLoaded={(e: any) => console.log('Envelope loaded', e)}
      />

      <FlexFill />
    </div>
  );
};
