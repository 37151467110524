import {toast} from 'react-toastify';
import {format} from '@formkit/tempo';
import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate, useSearchParams} from 'react-router-dom';
import TriangleDownIcon from '../../assets/TriangleDown.svg?react';
import {cancelEnvelope, TEnvelopeStatus, VerdocsEndpoint} from '@verdocs/js-sdk';
import {VerdocsPagination, VerdocsSpinner, VerdocsStatusIndicator} from '@verdocs/web-sdk-react';
import {SButton, Search, Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from '../../Components';
import {PageTitle, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger} from '../../Components';
import {getRecipientsWithActions, userCanAct, userCanCancelEnvelope} from '@verdocs/js-sdk';
import {IListEnvelopesParams, invalidateQuery, useEnvelopes} from '../../react-sdk/queries';
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem} from '../../Components';
import {OkCancelDialog} from '../../Dialogs';
import {AppState} from '../../AppState';
import {URLS} from '../../Routing';
import './EnvelopeManager.css';

const StatusFilters = [
  {value: 'all', label: 'All'},
  {value: 'pending', label: 'Pending'},
  {value: 'in progress', label: 'In Progress'},
  {value: 'complete', label: 'Completed'},
  {value: 'declined', label: 'Declined'},
  {value: 'canceled', label: 'Cancelled'},
];

const SortOptions = [
  {value: 'envelope_name', label: 'Name'},
  {value: 'envelope_status', label: 'Status'},
  {value: 'created_at', label: 'Created'},
  {value: 'updated_at', label: 'Last Updated'},
  {value: 'canceled_at', label: 'Canceled'},
];

const ViewFilters = [
  {value: 'all', label: 'All'},
  {value: 'inbox', label: 'Inbox'},
  {value: 'sent', label: 'Sent'},
  {value: 'completed', label: 'Completed'},
  {value: 'action', label: 'Action Required'},
  {value: 'waiting', label: 'Waiting on Others'},
];

export const EnvelopesSummary: React.FC<{rowsPerPage?: number; showViewAll?: boolean}> = observer(
  ({rowsPerPage = 20, showViewAll = false}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // const [defaultSort, setDefaultSort] = useLocalStorage('envelopesDefaultSort', 'updated_at');
    // const [defaultView, setDefaultView] = useLocalStorage('envelopesDefaultView', 'all');
    // const [defaultStatus, setDefaultStatus] = useLocalStorage('envelopesDefaulStatus', 'all');
    const [processing, setProcessing] = useState(false);
    const [selectedPage, setSelectedPage] = useState(0);
    const [cancelingEnvelopeId, setCancelingEnvelopeId] = useState('');
    const [search, setSearch] = React.useState('');

    // TEnvelopeStatus | 'all'
    const [status, setStatus] = React.useState<TEnvelopeStatus | 'all'>((searchParams.get('status') as TEnvelopeStatus) || 'all');
    // 'inbox' | 'sent' | 'action' | 'waiting' | 'completed' | 'all'
    const [view, setView] = React.useState<string>(searchParams.get('view') || 'all');
    // TEnvelopeSort
    const [sortBy, setSortBy] = React.useState<string>(searchParams.get('sort') || 'updated_at');

    let queryParams: IListEnvelopesParams = {
      page: selectedPage,
      sort_by: sortBy as any,
      rows: rowsPerPage,
      view: view !== 'all' ? (view as any) : undefined,
    };

    if (view === 'all') {
      if (status !== 'all') {
        queryParams.status = [status];
      }
    }

    if (sortBy !== 'updated_at') {
      queryParams.sort_by = sortBy as any;
      queryParams.ascending = sortBy === 'name';
    }

    if (search.trim()) {
      queryParams.q = search.trim();
    }

    const envelopesQuery = useEnvelopes(queryParams);

    const handleCancelEnvelope = (id: string) => {
      // Hack to clear the internal dropdown when it's not removed
      document.getElementById('verdocs-dropdown-menu-items')?.remove();

      setProcessing(true);
      cancelEnvelope(VerdocsEndpoint.getDefault(), id)
        .then((r) => {
          console.log('Canceled', r);
          // TODO: Update the cached list directly
          setCancelingEnvelopeId('');
          setProcessing(false);
          invalidateQuery(['envelopes', 'summary', 0]);
          return envelopesQuery.refetch();
        })
        .catch((e) => {
          console.log('Error deleting template', e);
          setProcessing(false);
        });
    };
    return (
      <div className="envelope-manager">
        <PageTitle>
          Envelopes
          <div className="flex flex-1" />
        </PageTitle>

        <div className="filters">
          <div className="filter">
            <Search placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>

          <div className="filter">
            <Select value={view} onValueChange={setView}>
              <SelectTrigger className="w-[220px] justify-start gap-2">
                <div className="text-[#654dcb] flex flex-0 font-medium">View:</div>
                <div className="flex flex-1">
                  <SelectValue className="flex flex-1" placeholder="View..." />
                </div>
              </SelectTrigger>

              <SelectContent>
                {ViewFilters.map((filter) => (
                  <SelectItem key={filter.value} value={filter.value}>
                    {filter.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="filter">
            <Select value={status} onValueChange={(e: TEnvelopeStatus) => setStatus(e)}>
              <SelectTrigger className="w-[190px] justify-start gap-2">
                <div className="text-[#654dcb] flex flex-0 font-medium">Status:</div>
                <div className="flex flex-1">
                  <SelectValue className="flex flex-1" placeholder="Status..." />
                </div>
              </SelectTrigger>

              <SelectContent>
                {StatusFilters.map((filter) => (
                  <SelectItem key={filter.value} value={filter.value}>
                    {filter.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="filter">
            <Select value={sortBy} onValueChange={setSortBy}>
              <SelectTrigger className="w-[180px] justify-start gap-2">
                <div className="text-[#654dcb] flex flex-0 font-medium">Sort:</div>
                <div className="flex flex-1">
                  <SelectValue className="flex flex-1" placeholder="Sort..." />
                </div>
              </SelectTrigger>
              <SelectContent>
                {SortOptions.map((filter) => (
                  <SelectItem key={filter.value} value={filter.value}>
                    {filter.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {envelopesQuery.isFetching && <VerdocsSpinner mode="dark" size={24} />}
          <div style={{flex: 1}} />
        </div>

        {(envelopesQuery.data?.envelopes || []).map((envelope) => {
          const recipientsWithActions = getRecipientsWithActions(envelope);
          const userCanCancel = userCanCancelEnvelope(AppState.currentProfile, envelope);
          const userRole = recipientsWithActions.find((recipient) => recipient.email === VerdocsEndpoint.getDefault().session?.email || '');
          const userCanFinish =
            userRole && userCanCancel && userCanAct(VerdocsEndpoint.getDefault().session?.email || '', recipientsWithActions);

          return (
            <div className="envelope" onClick={() => navigate(URLS.ENVELOPE_DETAIL.build(envelope.id))} key={envelope.id}>
              <div className="envelope-field envelope-field-name">{envelope.name}</div>
              <div className="envelope-field envelope-field-status w-[130px] items-center justify-center">
                <VerdocsStatusIndicator status={envelope.status} />
              </div>
              <div className="envelope-field envelope-field-date hidden tablet:flex">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px'}}>
                  {format(new Date(envelope.updated_at), 'M/D/YYYY', 'en')}
                </div>
              </div>
              <div className="envelope-field envelope-field-actions">
                <div
                  onClick={(e: any) => {
                    // Stop this from triggering the row selector
                    e.stopPropagation();
                  }}>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <SButton variant="outline" size="sm" className="h-7 p-1.5">
                        <TriangleDownIcon className="size-4" />
                      </SButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>Envelope Actions</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => navigate(URLS.ENVELOPE_DETAIL.build(envelope.id))}>View</DropdownMenuItem>
                      <DropdownMenuItem onClick={() => navigate(URLS.ENVELOPE_FINISH.build(envelope.id, userRole?.role_name || ''))}>
                        Finish
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setCancelingEnvelopeId(envelope.id)} disabled={!userCanCancel}>
                        Cancel
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          );
        })}

        {!!envelopesQuery?.data?.count && (
          <div style={{marginTop: 20}}>
            <VerdocsPagination
              selectedPage={envelopesQuery?.data?.page}
              perPage={envelopesQuery?.data?.rows}
              itemCount={envelopesQuery.data?.count}
              onSelectPage={(e: any) => setSelectedPage(e.detail.selectedPage)}
            />
          </div>
        )}

        {!envelopesQuery.isLoading && (envelopesQuery.data?.envelopes || []).length < 1 && (
          <div className="empty">No matching envelopes found. Please adjust your filters and try again.</div>
        )}

        {cancelingEnvelopeId && (
          <OkCancelDialog
            onCancel={() => setCancelingEnvelopeId('')}
            onOk={async () => {
              await handleCancelEnvelope(cancelingEnvelopeId);
              setCancelingEnvelopeId('');
              toast.info('Envelope cancelled.');
              invalidateQuery(['envelopes']);
              invalidateQuery(['envelopes', 'list']);
            }}>
            Are you sure you want to cancel this envelope? This action cannot be undone.
          </OkCancelDialog>
        )}
      </div>
    );
  },
);
