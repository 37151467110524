import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {VerdocsAuth, VerdocsPreview} from '@verdocs/web-sdk-react';
import {IProfile, TSession, VerdocsEndpoint} from '@verdocs/js-sdk';

export const Send: React.FC = () => {
  const {id = ''} = useParams();
  const [hasSession, setHasSession] = useState(false);

  useEffect(() => {
    const endpoint = VerdocsEndpoint.getDefault();

    const unsub = endpoint.onSessionChanged((_endpoint: VerdocsEndpoint, session: TSession, profile: IProfile | null) => {
      const originalPath = localStorage.getItem('originalPath');
      console.log('[PREVIEW] Session changed', {session, profile, originalPath});

      if (session) {
        setHasSession(true);
      } else {
        setHasSession(false);
      }
    });

    endpoint.loadSession();

    return unsub;
  }, []);

  if (!hasSession) {
    return <VerdocsAuth />;
  }

  return <VerdocsPreview style={{width: '100%', height: '100%'}} templateId={id} />;
};
