import {twMerge} from 'tailwind-merge';
import React, {FC, Fragment} from 'react';

interface ITab {
  id: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
}

interface ITabsProps {
  tabs: ITab[];
  activeTab: string;
  onSelect: (id: string) => void;
}

const Tab: FC<{activeTab: string; tab: ITab; onSelect: (id: string) => void}> = ({activeTab, tab, onSelect}) => {
  return (
    <div
      id={`tooltip-tab-${tab.id}`}
      className={twMerge(
        'font-bold cursor-pointer px-2 h-[34px] flex items-center justify-center text-sm',
        activeTab === tab.id ? 'text-[#707ae5] bg-white border-b-[#707ae5] border-b-2' : '',
        tab.disabled ? 'cursor-default text-gray-300' : '',
      )}
      onClick={() => (tab.disabled ? () => {} : onSelect(tab.id))}
      data-tooltip-html={tab.disabled && tab.tooltip && tab.tooltip ? tab.tooltip : undefined}>
      {tab.label}
    </div>
  );
};

export const Tabs: FC<ITabsProps> = ({tabs, activeTab, onSelect}) => {
  return (
    <div className="flex flex-row flex-wrap items-end text-[#72757d] gap-2 py-2">
      {tabs.map((tab) => (
        <Fragment key={tab.id}>
          <Tab key={tab.id} tab={tab} activeTab={activeTab} onSelect={onSelect} />
        </Fragment>
      ))}

      <div className="flex flex-1" />
    </div>
  );
};
