import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {useOrganizationContacts, useOrganizationInvites, useOrganizationMembers} from '../../react-sdk/queries';
import {ContactsTable, InvitesTable, MembersTable} from './Components';
import {AddContactDialog, InviteMemberDialog} from '../../Dialogs';
import {AppState} from '../../AppState';
import {FlexFill, HelpDialog, PageTitle, Tabs} from '../../Components';

export const Members: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id;

  const [tab, setTab] = useState('members');
  const [inviting, setInviting] = React.useState(false);
  const [addingContact, setAddingContact] = React.useState(false);
  const invitesQuery = useOrganizationInvites(organizationId);
  const membersQuery = useOrganizationMembers(organizationId);
  const contactsQuery = useOrganizationContacts(organizationId);

  const invitesCount = invitesQuery.data?.length || 0;
  const membersCount = membersQuery.data?.length || 0;
  const contactsCount = contactsQuery.data?.length || 0;

  return (
    <div className="">
      <PageTitle>
        Members and Contacts
        <FlexFill />
        <HelpDialog title="Members and Contacts">
          <p>
            "Members" are users whom you invite to use Verdocs within your account. Members may share templates with one another and perform
            other operations based on their role and/or group membership. To add members to your organization, click the "Invite New Member"
            button.
          </p>
          <p>
            "Contacts" are users who are not members of your organization, but to whom you regularly send Envelopes for signing. Contacts
            cannot login to and/or access data within your organization, and appear only in the Contact Chooser when sending an Envelope.
          </p>
        </HelpDialog>
      </PageTitle>

      <Tabs
        tabs={[
          {id: 'members', label: `Members (${membersCount})`},
          {id: 'invites', label: `Invites (${invitesCount})`},
          {id: 'contacts', label: `Contacts (${contactsCount})`},
        ]}
        activeTab={tab}
        onSelect={setTab}
      />

      <div className="my-4" />

      {tab === 'members' && <MembersTable />}
      {tab === 'invites' && <InvitesTable />}
      {tab === 'contacts' && <ContactsTable />}

      <div className="my-4" />
      {tab === 'members' && <VerdocsButton size="small" label="Invite New Member" onClick={() => setInviting(true)} />}
      {tab === 'invites' && <VerdocsButton size="small" label="Invite New Member" onClick={() => setInviting(true)} />}
      {tab === 'contacts' && <VerdocsButton size="small" label="Add Contact" onClick={() => setAddingContact(true)} />}

      {inviting && (
        <InviteMemberDialog
          onClose={() => {
            setInviting(false);
            invitesQuery.refetch().catch((e: any) => console.log('Unknown Error', e));
            membersQuery.refetch().catch((e: any) => console.log('Unknown Error', e));
          }}
        />
      )}

      {addingContact && (
        <AddContactDialog
          onClose={() => {
            setAddingContact(false);
            contactsQuery.refetch().catch((e: any) => console.log('Unknown Error', e));
          }}
        />
      )}
    </div>
  );
});
