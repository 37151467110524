import React, {FC} from 'react';
import styled from '@emotion/styled';
import {twMerge} from 'tailwind-merge';
import {Spinner} from './Spinner';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'xsmall' | 'small' | 'normal' | 'medium' | 'large';
  icon?: JSX.Element;
  label: string;
  style?: any;
  spinner?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

const ButtonWrapper = styled.button`
  height: 32px;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  border-radius: 8px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;
  background-color: #50be80;
  border: 2px solid transparent;

  &.primary {
    color: #fff;
    border-color: #50be80;
    background-color: #50be80;

    &:hover {
      border-color: #2b995b;
      background-color: #2b995b;
    }
  }

  &.secondary {
    color: #654dcb;
    border-color: #654dcb;
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: #654dcb;
    }
  }

  &.outline {
    color: #fff;
    border-color: #fff;
    background-color: transparent;

    &:hover {
      color: #654dcb;
      background-color: #fff;
      border-color: #fff;
    }
  }

  &.disabled {
    color: #fff;
    cursor: default;
    border-color: #aeb4bf;
    background-color: #aeb4bf;

    &:hover {
      color: #fff;
      border-color: #aeb4bf;
      background-color: #aeb4bf;
    }
  }
`;

const AnnotationWrapper = styled.div`
  top: 50%;
  display: flex;
  left: 10px;
  margin-top: -13px; // Assumes an approx. 17px-high icon
  position: absolute;
`;

// Shorter than the equivalent CSS
const heights = {xsmall: 26, small: 36, normal: 48, medium: 55, large: 62};
const hPaddings = {xsmall: 10, small: 20, normal: 35, medium: 45, large: 60};
const fontSizes = {xsmall: '12px', small: '13px', normal: '14px', medium: '16px', large: '18px'};
const borderRadii = {xsmall: 3, small: 4, normal: 5, medium: 6, large: 7};

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'small',
  type,
  label,
  icon,
  disabled = false,
  spinner = false,
  style,
  onClick,
  className = '',
}) => {
  const height = heights[size];
  const paddingLeft = !!icon ? hPaddings[size] - 10 : hPaddings[size];
  const paddingRight = !!icon ? hPaddings[size] - 10 : hPaddings[size];
  const borderRadius = borderRadii[size];
  const fontSize = fontSizes[size];
  const customStyle = {height, fontSize, borderRadius, paddingLeft, paddingRight, ...(style || {})};

  return (
    <ButtonWrapper
      onClick={disabled ? () => {} : onClick}
      className={twMerge(`${size} ${variant} ${disabled ? 'disabled' : ''}`, className)}
      style={customStyle}
      type={type}>
      {spinner ? (
        <AnnotationWrapper>
          <Spinner size={20} mode={'light'} />
        </AnnotationWrapper>
      ) : (
        <></>
      )}
      {icon ? <span style={{marginRight: 20}}>{icon}</span> : <></>}
      {label}
    </ButtonWrapper>
  );
};

interface IconButtonProps {
  icon: any;
  style?: any;
  disabled?: boolean;
  onClick: () => void;
}

const IconWrapper = styled.button`
  width: 56px;
  color: white;
  border: none;
  height: 56px;
  transition: 0.2s;
  padding: 10px 20px;
  align-items: center;
  border-radius: 56px;
  display: inline-flex;
  justify-content: center;
  background-color: #55b591;

  &:hover {
    cursor: pointer;
    background-color: #2b995b;
  }

  &.disabled {
    cursor: default;
    border-color: #aeb4bf;
    background-color: #aeb4bf;
  }
`;

export const IconButton: FC<IconButtonProps> = ({icon, style, disabled, onClick}) => {
  return (
    <IconWrapper onClick={disabled ? () => {} : onClick} className={disabled ? 'disabled' : ''} style={style}>
      <span dangerouslySetInnerHTML={icon} />
    </IconWrapper>
  );
};
