import {z} from 'zod';
import React, {FC} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
import {getOrganization, updateOrganization, VerdocsEndpoint} from '@verdocs/js-sdk';
import {convertToE164, IOrganization, updateOrganizationLogo, updateOrganizationThumbnail} from '@verdocs/js-sdk';
import {FormColumn, FormColumns, PhotoSelector, ColorPicker, TextInput, HelpDialog, FormLabel, FlexFill} from '../../Components';
import {invalidateQuery} from '../../react-sdk/queries';
import {AppState} from '../../AppState';

const schema = z.object({
  name: z.string().trim().min(1, 'Name is required').max(30),
  address: z.string().trim().max(30).optional(),
  address2: z.string().trim().max(30).optional(),
  phone: z.preprocess((val) => convertToE164(String(val).trim()), z.string().optional()),
  contact_email: z.string().trim().email('Invalid email').optional().or(z.literal('')),
  url: z.string().trim().optional(),
  primary_color: z.string().trim().optional(),
  secondary_color: z.string().trim().optional(),
  full_logo_url: z.string().trim().optional(),
  thumbnail_url: z.string().trim().optional(),
});

type SchemaType = z.infer<typeof schema>;

const getEditableFields = (organization?: IOrganization) => ({
  name: organization?.name || '',
  address: organization?.address || '',
  address2: organization?.address2 || '',
  phone: organization?.phone || '',
  contact_email: organization?.contact_email || '',
  url: organization?.url || '',
  primary_color: organization?.primary_color || '',
  secondary_color: organization?.secondary_color || '',
  full_logo_url: organization?.full_logo_url || '',
  thumbnail_url: organization?.thumbnail_url || '',
});

export const Organization: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id || '';

  const [newLogo, setNewLogo] = React.useState<File | null>(null);
  const [newThumbnail, setNewThumbnail] = React.useState<File | null>(null);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: async () => getOrganization(VerdocsEndpoint.getDefault(), organizationId).then(getEditableFields),
  });

  const onSubmit = async (data: any) => {
    if (newLogo) {
      await updateOrganizationLogo(VerdocsEndpoint.getDefault(), organizationId, newLogo)
        .then(() => setNewLogo(null))
        .catch((e) => console.log('Unknown error', e));
    }

    if (newThumbnail) {
      await updateOrganizationThumbnail(VerdocsEndpoint.getDefault(), organizationId, newThumbnail)
        .then(() => setNewThumbnail(null))
        .catch((e) => console.log('Unknown error', e));
    }

    await updateOrganization(VerdocsEndpoint.getDefault(), organizationId, {
      name: data.name,
      address: data.address,
      address2: data.address2,
      phone: data.phone,
      contact_email: data.contact_email,
      url: data.url,
      primary_color: data.primary_color,
      secondary_color: data.secondary_color,
      // Left here for documentation. We edit these through a different path.
      // full_logo_url: data.full_logo_url,
      // thumbnail_url: data.thumbnail_url,
    })
      .then((r) => {
        form.reset(getEditableFields(r));
        invalidateQuery(['organization']);
      })
      .catch((e) => toast.error(`Error updating organization: ${e.message}`));
  };

  const dirty = form.formState.isDirty || newLogo || newThumbnail;

  return (
    <div className="px-4 py-6 w-full max-w-[640px]">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormColumns>
            <FormColumn className="flex-[2]">
              <TextInput label="Name" name="name" required />
              <TextInput label="Web Site" name="url" />
              <TextInput label="Contact Phone" name="phone" />
              <TextInput label="Contact Email" name="contact_email" />
              <TextInput label="Address" name="address" />
              <TextInput label="Address 2" {...form.register('address2')} name="address2" />
            </FormColumn>

            <FormColumn className="items-start">
              <FormLabel className="flex flex-row w-full">
                <div>Logo</div>
                <FlexFill />
                <HelpDialog title="Logo and Thumbnail">
                  <p className="mb-4">
                    The logo displayed at the top of email templates and in other locations in rectangular regions, and should typically be
                    the wide-form version of your organization's logo.
                  </p>
                  <p className="mb-4">The thumbnail is displayed in smaller areas where a square-form version is more suitable.</p>
                  <p className="mb-4">
                    If either (or both) of the logo/thumbnail are not provided, the Verdocs logos will be shown instead.
                  </p>
                </HelpDialog>
              </FormLabel>

              <PhotoSelector newFile={newLogo} value={form.getValues().full_logo_url} onSelectFile={setNewLogo} />

              <FormLabel className="mt-4">Thumbnail</FormLabel>
              <PhotoSelector newFile={newThumbnail} value={form.getValues().thumbnail_url} onSelectFile={setNewThumbnail} />

              <FormLabel className="flex flex-row w-full mt-4">
                <div>Primary Color</div>
                <FlexFill />
                <HelpDialog title="Primary and Secondary Colors">
                  <p>
                    The primary color is used as the background in email templates and other locations and may be used to provide a brand
                    color for theming. Text will be displayed in these regions in white, so this should typically be dark.
                  </p>
                  <p>
                    The secondary color is used to style buttons and other assets. It is frequently used for assets that appear within
                    regions with a background of the primary color, so it should be complementary.
                  </p>
                  <p>
                    If either (or both) of the colors are not provided, the Verdocs colors of
                    <code className="bg-[#654dcb] text-white p-1 inline-block mx-1">#654dcb (primary)</code> and
                    <code className="bg-[#55bc81] text-white p-1 inline-block mx-1">#55bc81 (secondary)</code> will be used instead.
                  </p>
                </HelpDialog>
              </FormLabel>
              <ColorPicker name="primary_color" defaultValue="#654dcb" />

              <FormLabel className="mt-4">Secondary Color</FormLabel>
              <ColorPicker name="secondary_color" defaultValue="#55bc81" />
            </FormColumn>
          </FormColumns>

          <VerdocsButton type="submit" label="Save" size="normal" disabled={form.formState.isSubmitting || !dirty} />
        </form>
      </FormProvider>
    </div>
  );
});
