import {action, observable} from 'mobx';
import {IProfile, TSession, VerdocsEndpoint} from '@verdocs/js-sdk';
import {clearQueryCache} from './react-sdk/queries';

export const AppState = observable({
  // Has our session been loaded? This doesn't mean we have one, just that we've determined if we do or not. It blocks the UI load.
  sessionLoaded: false,

  // Are we authenticated? Never set until sessionLoaded is also set.
  isAuthenticated: false,
  authSession: null as TSession,
  currentProfile: null as IProfile | null,
});

export const setSession = action((session: TSession, profile: IProfile | null) => {
  // setSession gets called a lot as components that depend on Auth re-render. We don't
  // need a state update for for every one.
  if (AppState.isAuthenticated && session === null) {
    console.log('[STATE] Logged out');
    AppState.isAuthenticated = false;
    AppState.authSession = null;
    AppState.currentProfile = null;

    // TODO: Possible loop trigger, if we come back here over and over
    VerdocsEndpoint.getDefault().clearSession();
  } else if (!AppState.isAuthenticated && session !== null) {
    console.log('[STATE] Authenticated', profile);
    AppState.isAuthenticated = true;
    AppState.authSession = session;
    AppState.currentProfile = profile;
    clearQueryCache();
  }

  // No matter how we got here, we know at least something know. Let the route handlers begin.
  AppState.sessionLoaded = true;

  // TODO: https://app.elev.io/installation Elev requires a SHA256 HMAC of user data that's expensive to calculate in the browser.
  // But it doesn't seem to add much to the product for us to send user data anyway, so we skipped it for now. Left this here as
  // documentation. It would require a new API call to calculate this on the server anyway, because it's hashed with the secret key.
  // @ts-ignore
  // if (window._elev) {
  //   console.log('Identifying user to Elev', session);
  // }
});
