import {z} from 'zod';
import React, {FC} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {useForm, FormProvider} from 'react-hook-form';
import {convertToE164, IProfile, updateProfilePhoto} from '@verdocs/js-sdk';
import {getCurrentProfile, updateProfile, VerdocsEndpoint} from '@verdocs/js-sdk';
import {FormColumns, FormColumn, PhotoSelector, TextInput, PageTitle, FlexFill, HelpDialog} from '../../Components';
import {invalidateQuery, useProfiles} from '../../react-sdk/queries';

const schema = z.object({
  first_name: z.string().trim().min(1, 'First name is required').max(30),
  last_name: z.string().trim().min(1, 'Last name is required').max(30),
  email: z.string().trim().email('Invalid email').min(1, 'Email is required'),
  phone: z.preprocess((val) => convertToE164(String(val).trim()), z.string()),
});

type SchemaType = z.infer<typeof schema>;

const getEditableFields = (profile?: IProfile) => ({
  first_name: profile?.first_name || '',
  last_name: profile?.last_name || '',
  email: profile?.email || '',
  phone: profile?.phone || '',
});

export const Profile: FC = observer(() => {
  const [newProfilePhoto, setNewProfilePhoto] = React.useState<File | null>(null);
  const profilesQuery = useProfiles();

  const profiles = profilesQuery.data || [];
  const currentProfile = profiles.find((profile) => profile.current);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: async () => {
      return getCurrentProfile(VerdocsEndpoint.getDefault()).then(getEditableFields);
    },
  });

  // TODO: Refetch profile after update?
  const onSubmit = async (data: any) => {
    if (newProfilePhoto) {
      await updateProfilePhoto(VerdocsEndpoint.getDefault(), currentProfile!.id, newProfilePhoto)
        .then(() => setNewProfilePhoto(null))
        .catch((e) => console.log('Unknown error', e));
    }

    await updateProfile(VerdocsEndpoint.getDefault(), currentProfile!.id, {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
    })
      .then((r) => {
        form.reset(getEditableFields(r));
        invalidateQuery(['profiles']);
      })
      .catch((e) => toast.error(`Error updating profile: ${e.message}`));
  };

  return (
    <div className="max-w-[640px]">
      <PageTitle>
        My Profile
        <FlexFill />
        <HelpDialog title="My Profile">
          <p>
            Your Verdocs profile will be visible to other members of your organization. If you share templates publicly, your name and
            profile photo (never your email address or phone number) may be shared with users outside your organization as well.
          </p>
          <p>
            NOTE: Currently, you may not change your email address. Please contact &nbsp;{' '}
            <a href="mailto: support@verdocs.com">support@verdocs.com</a> if you wish to do so.
          </p>
        </HelpDialog>
      </PageTitle>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormColumns>
            <FormColumn className="flex-[2]">
              <TextInput label="First Name" name="first_name" required />
              <TextInput label="Last Name" name="last_name" required />
              <TextInput label="Email" disabled name="email" />
              <TextInput label="Phone" name="phone" />
            </FormColumn>

            <FormColumn className="items-start">
              <PhotoSelector rounded newFile={newProfilePhoto} value={currentProfile?.picture} onSelectFile={setNewProfilePhoto} />
            </FormColumn>
          </FormColumns>

          <VerdocsButton
            type="submit"
            label="Save"
            size="normal"
            disabled={form.formState.isSubmitting || (!form.formState.isDirty && !newProfilePhoto)}
          />
        </form>
      </FormProvider>
    </div>
  );
});
