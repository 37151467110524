import {toast} from 'react-toastify';
import {format} from '@formkit/tempo';
import React, {FC, useState} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {InboxIcon, CalendarIcon} from '@heroicons/react/24/solid';
import TriangleDownIcon from '../../assets/TriangleDown.svg?react';
import {FileLockIcon, EarthIcon, Building2Icon} from 'lucide-react';
import {userCanCreateTemplate, deleteTemplate} from '@verdocs/js-sdk';
import {ITemplate, TSortTemplateBy, VerdocsEndpoint} from '@verdocs/js-sdk';
import {VerdocsButton, VerdocsPagination, VerdocsSpinner} from '@verdocs/web-sdk-react';
import {Search, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Table, TableCell, TableRow} from '../../Components';
import {DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, PageTitle} from '../../Components';
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem} from '../../Components';
import {invalidateQuery, useTemplates} from '../../react-sdk/queries';
import {DuplicateTemplateDialog, OkCancelDialog} from '../../Dialogs';
import {URLS} from '../../Routing';

const VisibilityFilters = [
  {value: 'private_shared', label: 'Personal + Shared'},
  {value: 'private', label: 'Personal'},
  {value: 'shared', label: 'Shared'},
  {value: 'public', label: 'Public'},
];

const SortOptions = [
  {value: 'name', label: 'Name'},
  {value: 'created_at', label: 'Created'},
  {value: 'updated_at', label: 'Last Updated'},
  {value: 'last_used_at', label: 'Last Used'},
  {value: 'counter', label: 'Most Used'},
  {value: 'star_counter', label: 'Most Starred'},
];

// TODO: Move to SDK
export type TTemplateVisibilityFilter = 'private_shared' | 'private' | 'shared' | 'public';

export const TemplateSummary: FC<{rowsPerPage?: number; showViewAll?: boolean}> = ({rowsPerPage = 20, showViewAll = false}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [deletingTemplateId, setDeletingTemplateId] = useState('');
  const [duplicatingTemplate, setDuplicatingTemplate] = useState<ITemplate | null>(null);
  const [defaultSort, setDefaultSort] = useLocalStorage('templatesDefaultSort', 'created_at');
  const [defaultVisibility, setDefaultVisibility] = useLocalStorage('templatesDefaultVisibility', 'private_shared');
  const [search, setSearch] = React.useState('');
  const [selectedPage, setSelectedPage] = useState(0);

  const [visibility, setVisibility] = React.useState<TTemplateVisibilityFilter>(() => {
    // Temporary fixup for folks with pre-existing invalid filters
    const is = (searchParams.get('visibility') || defaultVisibility) as TTemplateVisibilityFilter;
    if (!VisibilityFilters.find((f) => f.value == is)) {
      return 'private_shared';
    }
    return is;
  });
  const [sortBy, setSortBy] = React.useState<TSortTemplateBy>((searchParams.get('sort') || defaultSort) as TSortTemplateBy);

  const templatesQuery = useTemplates({
    q: search || undefined,
    visibility,
    sort_by: sortBy,
    page: selectedPage,
    ascending: sortBy === 'name',
    rows: rowsPerPage,
  });

  const handleSetSortBy = (sortBy: TSortTemplateBy) => {
    setSortBy(sortBy);
    setDefaultSort(sortBy);
  };

  const handleSetVisibility = (sharing: TTemplateVisibilityFilter) => {
    setVisibility(sharing);
    setDefaultVisibility(sharing);
  };

  return (
    <div className="flex flex-0 flex-col pb-8">
      <div className="flex flex-row mb-4 gap-4">
        <PageTitle>Templates</PageTitle>
        <div className="flex flex-1" />
        {userCanCreateTemplate(VerdocsEndpoint.getDefault().profile!) && (
          <VerdocsButton
            variant="standard"
            size="small"
            label="New Template"
            onClick={() => navigate(URLS.NEW_TEMPLATE.build())}
            style={{marginLeft: 'auto'}}
          />
        )}
        {showViewAll && (
          <VerdocsButton
            variant="standard"
            size="small"
            label="View All"
            onClick={() => navigate('/templates')}
            style={{marginLeft: 'auto'}}
          />
        )}
      </div>

      <div className="mb-5 flex flex-row flex-wrap gap-x-3 gap-y-2 template-filters items-center">
        <Search placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />

        <div className="dropdown">
          <Select value={visibility} onValueChange={handleSetVisibility}>
            <SelectTrigger className="w-[250px] justify-start gap-2">
              <div className="text-[#654dcb] flex flex-0 font-medium">Visibility:</div>
              <div className="flex flex-1">
                <SelectValue className="flex flex-1" placeholder="Visibility..." />
              </div>
            </SelectTrigger>
            <SelectContent>
              {VisibilityFilters.map((filter) => (
                <SelectItem key={filter.value} value={filter.value}>
                  {filter.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="dropdown">
          <Select value={sortBy} onValueChange={handleSetSortBy}>
            <SelectTrigger className="w-[190px] justify-start gap-2">
              <div className="text-[#654dcb] flex flex-0 font-medium">Sort:</div>
              <div className="flex flex-1">
                <SelectValue className="flex flex-1" placeholder="Sort..." />
              </div>
            </SelectTrigger>

            <SelectContent>
              {SortOptions.map((filter) => (
                <SelectItem key={filter.value} value={filter.value}>
                  {filter.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {templatesQuery.isFetching && <VerdocsSpinner mode="dark" size={24} />}
      </div>

      <Table className="bg-white text-gray-700 text-base">
        <tbody>
          {(templatesQuery.data?.templates || []).map((template) => (
            <TableRow
              className="border-b-[1px_solid_#f3f2f1] hover:cursor-pointer hover:bg-gray-50"
              key={template.id}
              onClick={() => navigate(URLS.TEMPLATE_DETAIL.build(template.id, 'preview'))}>
              <TableCell className="p-3 overflow-hidden whitespace-nowrap text-ellipsis">{template.name}</TableCell>

              <TableCell className="p-3 overflow-hidden whitespace-nowrap text-ellipsis hide-tablet" width="70px">
                <div className="flex flex-row gap-2 items-center">
                  {template.counter !== undefined && <InboxIcon className="size-4 text-gray-500 mr-2" />}
                  {template.counter}
                </div>
              </TableCell>

              <TableCell className="p-3 overflow-hidden whitespace-nowrap text-ellipsis hide-mobile text-gray-600" width="110px">
                <div className="flex flex-row gap-2 items-center">
                  {template.visibility === 'private' && (
                    <>
                      <FileLockIcon className="size-4 text-gray-500" />
                      Personal
                    </>
                  )}
                  {template.visibility === 'shared' && (
                    <>
                      <Building2Icon className="size-4 text-gray-500" />
                      Shared
                    </>
                  )}
                  {template.visibility === 'public' && (
                    <>
                      <EarthIcon className="size-4 text-gray-500" />
                      Public
                    </>
                  )}
                </div>
              </TableCell>

              <TableCell className="p-3 overflow-hidden whitespace-nowrap hide-mobile items-center" width="130px">
                <div className="flex flex-row gap-2 items-center">
                  <CalendarIcon className="size-5 text-gray-500" />
                  {format(new Date(template.last_used_at || template.updated_at), 'M/D/YYYY', 'en')}
                </div>
              </TableCell>

              <TableCell className="p-3 overflow-hidden whitespace-nowrap text-ellipsis template-field-actions" width="60px">
                <div
                  onClick={(e: any) => {
                    // Stop this from triggering the row selector
                    e.stopPropagation();
                  }}>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <div className="h-7 p-1.5 border rounded">
                        <TriangleDownIcon className="size-4" />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent sideOffset={2} align="end">
                      <DropdownMenuLabel>Template Actions</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => navigate(URLS.TEMPLATE_DETAIL.build(template.id, 'preview'))}>
                        Preview / Send
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => navigate(URLS.TEMPLATE_DETAIL.build(template.id, 'fields'))}>Edit</DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setDuplicatingTemplate(template)}>Duplicate</DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => navigate(URLS.SUBMITTED_DATA.build(template.id))}>Submitted Data</DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => setDeletingTemplateId(template.id)}>Delete</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>

      {!!templatesQuery?.data?.count && (
        <div style={{marginTop: 20}}>
          <VerdocsPagination
            selectedPage={selectedPage}
            perPage={rowsPerPage}
            itemCount={templatesQuery.data?.count}
            onSelectPage={(e: any) => setSelectedPage(e.detail.selectedPage)}
          />
        </div>
      )}

      {(templatesQuery.data?.templates || []).length < 1 && (
        <div className="empty">No matching templates found. Please adjust your filters and try again.</div>
      )}

      {deletingTemplateId && (
        <OkCancelDialog
          onCancel={() => setDeletingTemplateId('')}
          onOk={async () => {
            await deleteTemplate(VerdocsEndpoint.getDefault(), deletingTemplateId);
            toast.info('Template deleted.');
            invalidateQuery(['templates']);
            invalidateQuery(['templates', 'list']);
            setDeletingTemplateId('');
          }}>
          Are you sure you want to delete this template? This action cannot be undone.
        </OkCancelDialog>
      )}

      {!!duplicatingTemplate && (
        <DuplicateTemplateDialog
          template={duplicatingTemplate}
          onSave={() => {
            setDuplicatingTemplate(null);
            return templatesQuery.refetch();
          }}
          onCancel={() => setDuplicatingTemplate(null)}
        />
      )}
    </div>
  );
};
