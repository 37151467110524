import React, {FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {VerdocsEnvelopeSidebar, VerdocsView} from '@verdocs/web-sdk-react';
import {invalidateQuery} from '../../react-sdk/queries';
import {NonScrollingView} from '../../Components';
import {URLS} from '../../Routing';

export const EnvelopeDetail: FC = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const handleEnvelopeUpdated = () => {
    invalidateQuery(['envelopes']);
    invalidateQuery(['envelopes', id]);
  };

  return (
    <NonScrollingView className="p-0 tablet:p-0">
      <div className="flex flex-1 flex-row h-[calc(100vh-106px)] overflow-y-scroll">
        <VerdocsView className="flex-1" envelopeId={id} onEnvelopeUpdated={handleEnvelopeUpdated} />
        <VerdocsEnvelopeSidebar
          envelopeId={id}
          onEnvelopeUpdated={handleEnvelopeUpdated}
          onAnother={(e: any) => e.detail.envelope.template_id && navigate(URLS.TEMPLATE_DETAIL.build(e.detail.envelope.template_id, 'preview'))}
        />
      </div>
    </NonScrollingView>
  );
};
