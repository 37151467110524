import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {StandardView} from '../../Components';

export const NotFound: FC = observer(() => {
  return (
    <StandardView>
      <div className="flex min-h-full justify-center items-center flex-col">
        <div className="text-3xl mb-6 font-medium">Not Found</div>
        <div className="text-lg">Sorry, the content you were looking for could not be loaded.</div>
      </div>
    </StandardView>
  );
});
