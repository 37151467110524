import {useQuery} from '@tanstack/react-query';
import {getProfiles, VerdocsEndpoint} from '@verdocs/js-sdk';
import {setSession} from '../../AppState';

export const useProfiles = () =>
  useQuery({
    queryKey: ['profiles'],
    queryFn: () =>
      getProfiles(VerdocsEndpoint.getDefault()).catch((e) => {
        // NOTE: We used to do this globally but if ANY endpoint had an issue, no matter how
        // minor (or dev-related bug), we would get booted.
        if (e.response?.status === 401) {
          console.log('Session expired');
          setSession(null, null);
          // This was causing window reloads when accessing tabs where routes were unavailable.
          // TODO: Let's find another way
          // A reload is sufficient, the app loading routines will handle session expiration and redirect-to-login automatically
          // window.location.reload();
        }

        throw e;
      }),
  });

export const useCurrentProfile = () => {
  const {data: profiles} = useProfiles();
  return (profiles || []).find((profile) => profile.current);
};

export interface ISignupSurvey {
  industry?: string;
  size?: string;
  source?: string;
  referral?: string;
  coupon?: string;
  reason?: string;
  hearabout?: string;
}

export const recordSignupSurvey = (params: ISignupSurvey) =>
  VerdocsEndpoint.getDefault()
    .api.post<{status: 'OK'}>('/v2/profiles/signup-survey', params)
    .then((r) => r.data);
